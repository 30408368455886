const ProjectPlanActivity = require('./ProjectPlanActivity');

/**
 * @typedef {Object & ProjectPlanActivityData} ProjectPlanAreaData
 * @property {number} project_id
 * @property {number} budget_area_days
 */

/**
 * @typedef {Object & ProjectPlanActivity} ProjectPlanArea
 * @property {number} projectId
 * @property {[]} children
 */
class ProjectPlanArea extends ProjectPlanActivity {
  /**
   * @param {ProjectPlanAreaData} data
   * @param {ProjectPlanTask[]} children
   */
  constructor(data, children = []) {
    super(data, children);
    this.projecId = data.project_id;
    this.budgetAreaDays = data.budget_area_days;
  }
}

module.exports = ProjectPlanArea;
