const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const FormSidebar = require('@common/sidebar/FormSidebar.react');

const CreateAreaSidebar = ({ canEdit, save, isSaving, areas }) => {
  const Body = ({ updateErrors, data, errors, onTextFieldChange }) => (
    <div>
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:200']}>
            <TextField
              id="name"
              name="name"
              label="Name"
              errorText={errors.name}
              onChange={onTextFieldChange}
              value={data.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
    </div>
  );

  const getSort = () => {
    const lastArea = areas[areas.length - 1];
    return lastArea ? lastArea.sort + 1 : 1;
  };

  return (
    <FormSidebar title="New area"
      data={{ name: '', sort: getSort() }}
      canEdit={canEdit}
      onSave={save}
      isSaving={isSaving}>
      <Body />
    </FormSidebar>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  isSaving: stores.store.areasStore.isCreating,
  areas: stores.store.areasStore.sortedAreas,
  save: (data) => stores.store.areasStore.createArea(data),
  canEdit: stores.store.authorizationStore.canEdit,
});

module.exports = inject(storeMapper)(observer(CreateAreaSidebar));
