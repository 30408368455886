const React = require('react');
const PropTypes = require('prop-types');
const Sidebar = require('@common/sidebar/Sidebar.react');
const withFormValidation = require('@common/FormValidator/withFormValidation');
const sidebarService = require('@common/sidebar/SidebarManager/services/SidebarService');
const isEqual = require('react-fast-compare');

/**
 * A sidebar ready to manage form data and related errors.
 * Given body can access updateErrors, data, errors, editMode, onDateFieldChange, onSelectChange,
 * onTextFieldChange.
 */
class FormSidebar extends React.Component {
  static close() {
    sidebarService.closeSidebar();
  }

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      data: props.data,
      isDirty: false,
    };

    this.onEditClick = this.onEditClick.bind(this);
    this.setData = this.setData.bind(this);
    this.onTextFieldChange = this.onTextFieldChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.save = this.save.bind(this);
    this.onDateFieldChange = this.onDateFieldChange.bind(this);
  }

  onEditClick() {
    this.setState({ editMode: true });
  }

  onTextFieldChange(e) {
    this.setData(e.target.name, e.target.value);
  }

  onSelectChange(e, item) {
    this.setData(e.target.name, item);
  }

  onDateFieldChange(e) {
    this.setData(e.target.name, moment(e.target.value).format('YYYY-MM-DD'));
  }

  /**
   * @param {string} name
   * @param {*} value
   */
  setData(name, value) {
    this.setState((state) => {
      const updatedData = {
        ...state.data,
        [name]: value,
      };
      return {
        data: updatedData,
        isDirty: !isEqual(this.props.data, updatedData),
      };
    });
  }

  getBody() {
    return React
      .cloneElement(this.props.children, {
        updateErrors: this.props.updateErrors,
        errors: this.props.errors,
        data: this.state.data,
        editMode: this.state.editMode,
        onTextFieldChange: this.onTextFieldChange,
        onSelectChange: this.onSelectChange,
        setData: this.setData,
        onDateFieldChange: this.onDateFieldChange,
      });
  }

  canSave() {
    return this.state.isDirty && this.props.isValid;
  }

  save() {
    this.props.onSave(this.state.data).done(FormSidebar.close);
  }

  render() {
    return (
      <Sidebar body={this.getBody()}
        hasUnsavedChanges={this.state.isDirty}
        onEdit={this.onEditClick}
        canEdit={this.props.canEdit}
        canSave={this.canSave()}
        onClose={FormSidebar.close}
        onCancel={FormSidebar.close}
        onSave={this.save}
        error={this.props.error}
        isSaving={this.props.isSaving}
        className={this.props.className}
        title={this.props.title}
        actions={this.props.actions}
        alert={this.props.alert} />
    );
  }
}

FormSidebar.defaultProps = {
  alert: null,
  actions: null,
  className: '',
  error: null,
};

FormSidebar.propTypes = {
  children: PropTypes.node.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.shape({}).isRequired,
  updateErrors: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  className: PropTypes.string,
  actions: PropTypes.element,
  alert: PropTypes.element,
  error: PropTypes.element,
};

module.exports = withFormValidation(FormSidebar);
