const React = require('react');
const PropTypes = require('prop-types');
const Escaper = require('@common/formatters/Escaper.react');

const ProjectInfoHeaderDescription = ({ name, joc, client, side }) => {
  const getJocColor = () => ({ backgroundColor: joc });

  return (
    <div className="wethod-info-header__description-content">
      <div className="invoice-plan__joc-color" style={getJocColor()} />
      <div className="wethod-info-header__description-text">
        <h1 className="wethod-info-header__title"><Escaper>{name}</Escaper></h1>
        <h2 className="wethod-info-header__subtitle"><Escaper>{client}</Escaper></h2>
      </div>
      {side}
    </div>
  );
};

ProjectInfoHeaderDescription.propTypes = {
  name: PropTypes.string,
  client: PropTypes.string,
  joc: PropTypes.string,
  side: PropTypes.node,
};

ProjectInfoHeaderDescription.defaultProps = {
  name: null,
  client: null,
  joc: null,
  side: null,
};

module.exports = ProjectInfoHeaderDescription;
