/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case */
const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const BudgetErrorBody = require('./BudgetErrorBody.react');
const ClashErrorBody = require('./ClashErrorBody.react');
const ApprovalErrorBody = require('./ApprovalErrorBody.react');
const WeekendErrorBody = require('./WeekendErrorBody.react');
const HolidayErrorBody = require('./HolidayErrorBody.react');

/**
 * Modal to inform user about error encountered while trying to repeat allocations.
 * @type {RepeatErrorModal}
 */
module.exports = class RepeatErrorModal extends React.Component {
  constructor(props) {
    super(props);

    this.length = this.getErrorTypes().length;

    this.state = { page: 0 };
  }

  /**
   * Return a list of all the error types in this.props.data.errors.
   * @returns {[]}
   */
  getErrorTypes() {
    const types = [];
    for (let i = 0; i < this.props.data.errors.length; i++) {
      const error = this.props.data.errors[i];
      const knownType = types.toString().indexOf(error.type) > -1;
      if (!knownType) {
        types.push(error.type);
      }
    }
    return types;
  }

  /**
   * Returns all the error of the given type.
   * @param type
   * @returns {*}
   */
  getErrors(type) {
    return this.props.data.errors.filter((error) => error.type === type);
  }

  onNextClick() {
    this.setState({ page: this.state.page + 1 });
  }

  onPlanClick() {
    this.props.recurringAdd(
      this.props.data.settings.employee,
      this.props.data.settings.plannings,
      this.props.includeHolidays,
      this.props.includeWeekends,
    );
  }

  isLastTab(type) {
    const types = this.getErrorTypes();
    return type === types[types.length - 1];
  }

  getPositiveAction = (e) => {
    if (this.isLastTab(e.target.name)) {
      return this.onPlanClick();
    }
    return this.onNextClick();
  }

  getIncludeOrExcludeAction = (e) => {
    if (e.target.name === 'includeHoliday') {
      this.props.confirmHolidayPlanning();
    }
    if (e.target.name === 'excludeHoliday') {
      this.props.excludeHolidayPlanning();
    }
    if (e.target.name === 'includeWeekend') {
      this.props.confirmWeekendPlanning();
    }
    if (e.target.name === 'excludeWeekend') {
      this.props.excludeWeekendPlanning();
    }
    this.props.setRecurringModeTimestamp();
  }

  componentDidUpdate(prevProps) {
    if (this.props.recurringModeTimestamp !== prevProps.recurringModeTimestamp) {
      if (this.isLastTab(this.props.modalType)) {
        return this.onPlanClick();
      }
      return this.onNextClick();
    }
  }

  getClassName() {
    return this.props.isSaving ? 'planning-people-repeat-error__container planning-people-repeat-error__container--disabled' : 'planning-people-repeat-error__container';
  }

  render() {
    const type = this.getErrorTypes()[this.state.page];
    switch (type) {
      case 'holiday':
        return (
          <Modal
            title="Holidays Allocation"
            onClose={this.props.onCancelClick}>
            <HolidayErrorBody
              errors={this.getErrors('holiday')}
              isLast={this.isLastTab('holiday')}
              onExcludeButtonClick={this.getIncludeOrExcludeAction}
              isSaving={this.props.isSaving}
              projectsInfo={this.props.projectsInfo}
              onPositiveButtonClick={this.getIncludeOrExcludeAction} />
          </Modal>
        );
      case 'weekend':
        return (
          <Modal
            title="Weekends Allocation"
            onClose={this.props.onCancelClick}>
            <WeekendErrorBody
              errors={this.getErrors('weekend')}
              isLast={this.isLastTab('weekend')}
              onExcludeButtonClick={this.getIncludeOrExcludeAction}
              isSaving={this.props.isSaving}
              projectsInfo={this.props.projectsInfo}
              onPositiveButtonClick={this.getIncludeOrExcludeAction} />
          </Modal>
        );
      case 'clash':
        return (
          <Modal
            title="Oops! Something went wrong"
            onClose={this.props.onCancelClick}>
            <ClashErrorBody
              errors={this.getErrors('clash')}
              isLast={this.isLastTab('clash')}
              onCancelClick={this.props.onCancelClick}
              isSaving={this.props.isSaving}
              onPositiveButtonClick={this.getIncludeOrExcludeAction} />
          </Modal>
        );
      case 'budget':
        return (
          <Modal
            title="Oops! Something went wrong"
            onClose={this.props.onCancelClick}>
            <BudgetErrorBody
              errors={this.getErrors('budget')}
              isLast={this.isLastTab('budget')}
              projectsInfo={this.props.projectsInfo}
              onCancelClick={this.props.onCancelClick}
              isSaving={this.props.isSaving}
              onPositiveButtonClick={this.getIncludeOrExcludeAction} />
          </Modal>
        );
      case 'approval':
        return (
          <Modal
            title="Oops! Something went wrong"
            onClose={this.props.onCancelClick}>
            <ApprovalErrorBody
              errors={this.getErrors('approval')}
              isLast={this.isLastTab('approval')}
              projectsInfo={this.props.projectsInfo}
              onCancelClick={this.props.onCancelClick}
              isSaving={this.props.isSaving}
              onPositiveButtonClick={this.getIncludeOrExcludeAction} />
          </Modal>
        );
      default:
        return null;
    }
  }
};
