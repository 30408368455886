const React = require('react');
const PropTypes = require('prop-types');
const TabSwitcher = require('@common/TabSwitcher.react');
const GanttModel = require('./models/Gantt');

const GanttPrecisionSelect = ({ precision, onChange }) => {
  const availablePrecisions = [{
    key: GanttModel.PRECISION_DAY,
    name: 'Days',
  }, {
    key: GanttModel.PRECISION_WEEK,
    name: 'Weeks',
  }, {
    key: GanttModel.PRECISION_MONTH,
    name: 'Months',
  }];
  return (
    <TabSwitcher handleClick={onChange} items={availablePrecisions} current={precision} />
  );
};

GanttPrecisionSelect.defaultProps = {};

GanttPrecisionSelect.propTypes = {
  precision: PropTypes.oneOf([
    GanttModel.PRECISION_DAY,
    GanttModel.PRECISION_WEEK,
    GanttModel.PRECISION_MONTH,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

module.exports = GanttPrecisionSelect;
