const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const GanttHeaderCell = ({ children, days, columnWidth, showCurrent }) => {
  const width = days.length * columnWidth;

  const isCurrent = days.some((day) => day.isSame(moment(), 'day'));

  const getClassName = () => {
    let name = 'wethod-gantt-header__cell';
    if (isCurrent && showCurrent) {
      name += ' wethod-gantt-header__cell--current';
    }
    return name;
  };

  return (
    <div className={getClassName()} style={{ width }}>{children}</div>
  );
};

GanttHeaderCell.defaultProps = {
  showCurrent: false,
};

GanttHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
  columnWidth: PropTypes.number.isRequired,
  showCurrent: PropTypes.bool,
};

module.exports = GanttHeaderCell;
