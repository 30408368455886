/* eslint-disable class-methods-use-this */
const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const DayPicker = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const FormSidebar = require('@common/sidebar/FormSidebar.react');

const AreaSidebar = ({ area, canEdit, save, isSaving }) => {
  function onSave(data) {
    return save(area.id, data);
  }

  const Body = ({ updateErrors, data, errors, editMode, onTextFieldChange }) => (
    <div>
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:200']}>
            <TextField
              id="name"
              name="name"
              label="Name"
              errorText={errors.name}
              onChange={onTextFieldChange}
              readOnly={!editMode}
              value={data.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <DayPicker
            id="start"
            name="start"
            label="Starts"
            readOnly
            value={area.start} />
        </InputWrapper>
        <InputWrapper>
          <DayPicker
            id="end"
            name="end"
            label="Ends"
            readOnly
            value={area.end} />
        </InputWrapper>
      </Row>
    </div>
  );

  return (
    <FormSidebar title="Area details"
      data={{ name: area.name }}
      canEdit={canEdit}
      onSave={onSave}
      isSaving={isSaving}>
      <Body />
    </FormSidebar>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  isSaving: stores.store.areasStore.isSaving,
  save: (id, data) => stores.store.areasStore.updateArea(id, data),
  canEdit: stores.store.authorizationStore.canEdit,
});

module.exports = inject(storeMapper)(observer(AreaSidebar));
