const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const GanttPrecisionSelect = require('@common/Gantt/GanttPrecisionSelect.react');
const NewActivityButton = require('./NewActivityButton.react');

const ProjectPlanActions = ({ ganttPrecision, updateGanttPrecision }) => (
  <div className="wethod-project-plan-actions">
    <div className="wethod-project-plan-actions__left" />
    <div className="wethod-project-plan-actions__right">
      <GanttPrecisionSelect onChange={updateGanttPrecision} precision={ganttPrecision} />
      <NewActivityButton />
    </div>
  </div>
);

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  ganttPrecision: stores.store.uiStore.ganttPrecision,
  updateGanttPrecision: (precision) => stores.store.uiStore.updateGanttPrecision(precision),
});

module.exports = inject(storeMapper)(observer(ProjectPlanActions));
