const {
  makeAutoObservable,
  runInAction,
  reaction,
} = require('mobx');
const ProjectPlanTaskModel = require('@models/ProjectPlanTask');
const ProjectPlanTask = require('../models/ProjectPlanTask');

class ProjectPlanTaskStore {
  /**
   * @param {ProjectPlanRootStore} rootStore
   * @property {ProjectPlanTask[]} tasks
   * @property {boolean} isLoading
   * @property {boolean} isSaving
   * @property @property {number[]} deleting Ids of tasks which delete is pending
   */
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.tasks = [];
    this.isLoading = false;
    this.isSaving = false;
    this.deleting = [];

    makeAutoObservable(this);

    /**
     * React to subtasks change.
     */
    reaction(
      () => this.rootStore.subtasksStore.subtasks.map((subtask) => JSON.stringify(subtask)),
      () => this.updateChildren(),
    );
  }

  /**
   * Return sorted tasks.
   * @return {ProjectPlanTask[]}
   */
  get sortedTasks() {
    return [...this.tasks].sort((a, b) => a.sort - b.sort);
  }

  find(id) {
    return this.tasks.find((task) => task.id === id);
  }

  /**
   * Update tasks children.
   */
  updateChildren() {
    // eslint-disable-next-line no-param-reassign
    this.tasks.forEach((task) => task.children = this.rootStore.subtasksStore.findByTask(task.id));
  }

  /**
   * @param {number} areaId
   * @return {ProjectPlanTask[]}
   */
  findByArea(areaId) {
    return this.tasks.filter((task) => task.projecPlanAreaId === areaId);
  }

  loadTasks() {
    const { projectId } = this.rootStore;
    this.isLoading = true;
    ProjectPlanTaskModel.list(projectId).done((tasks) => {
      runInAction(() => {
        this.tasks = tasks
          .map((task) => new ProjectPlanTask(task, this.rootStore
            .subtasksStore.findByTask(task.id)));
        this.isLoading = false;
      });
    });
  }

  updateTask(id, data) {
    this.isSaving = true;
    ProjectPlanTaskModel.update(id, data).done((newTask) => {
      runInAction(() => {
        this.tasks = this.tasks.map((task) => {
          if (task.id === id) {
            return new ProjectPlanTask(newTask, this.rootStore.subtasksStore.findByTask(task.id));
          }
          return task;
        });

        this.isSaving = false;
      });
    });
  }

  createTask(projectPlanAreaId, data) {
    this.isSaving = true;
    return ProjectPlanTaskModel.create(projectPlanAreaId, data).done((newTask) => {
      runInAction(() => {
        this.tasks = this.tasks.concat(new ProjectPlanTask(newTask));
        this.isSaving = false;
      });
    });
  }

  /**
   * @param {number} id
   * @return {Promise}
   */
  deleteTask(id) {
    this.deleting.push(id);
    return ProjectPlanTaskModel.delete(id).done(() => {
      runInAction(() => {
        this.tasks = this.tasks.filter((task) => task.id !== id);
        this.deleting = this.deleting.filter((pendingId) => pendingId !== id);
      });
    });
  }

  isDeleting(id) {
    return this.deleting.includes(id);
  }
}

module.exports = ProjectPlanTaskStore;
