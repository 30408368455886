const {
  makeAutoObservable,
  runInAction,
} = require('mobx');
const ProjectPlanSubtaskModel = require('@models/ProjectPlanSubtask');
const ProjectPlanSubtask = require('../models/ProjectPlanSubtask');

class ProjectPlanSubtaskStore {
  /**
   * @param {ProjectPlanRootStore} rootStore
   * @property {ProjectPlanSubtask[]} subtasks
   * @property {boolean} isLoading
   * @property {boolean} isSaving
   * @property @property {number[]} deleting Ids of subtasks which delete is pending
   */
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.subtasks = [];
    this.isLoading = false;
    this.isSaving = false;
    this.deleting = [];

    makeAutoObservable(this);
  }

  /**
   * Return sorted subtasks.
   * @return {ProjectPlanSubtask[]}
   */
  get sortedSubtasks() {
    return [...this.subtasks].sort((a, b) => a.sort - b.sort);
  }

  /**
   * @param {number} taskId
   * @return {ProjectPlanSubtask[]}
   */
  findByTask(taskId) {
    return this.subtasks.filter((subtask) => subtask.projecPlanTaskId === taskId);
  }

  loadSubtasks() {
    const { projectId } = this.rootStore;
    this.isLoading = true;
    return ProjectPlanSubtaskModel.list(projectId).done((subtasks) => {
      runInAction(() => {
        this.subtasks = subtasks.map((subtask) => new ProjectPlanSubtask(subtask));
        this.isLoading = false;
      });
    });
  }

  updateSubtask(id, data) {
    this.isSaving = true;
    return ProjectPlanSubtaskModel.update(id, data).done((newSubtask) => {
      runInAction(() => {
        this.subtasks = this.subtasks.map((subtask) => {
          if (subtask.id === id) {
            return new ProjectPlanSubtask(newSubtask);
          }
          return subtask;
        });

        this.isSaving = false;
      });
    });
  }

  createSubtask(projectPlanTaskId, data) {
    this.isSaving = true;
    return ProjectPlanSubtaskModel.create(projectPlanTaskId, data).done((newSubtask) => {
      runInAction(() => {
        this.subtasks = this.subtasks.concat(new ProjectPlanSubtask(newSubtask));
        this.isSaving = false;
      });
    });
  }

  deleteSubtask(id) {
    this.deleting.push(id);
    return ProjectPlanSubtaskModel.delete(id).done(() => {
      runInAction(() => {
        this.subtasks = this.subtasks.filter((subtask) => subtask.id !== id);
        this.deleting = this.deleting.filter((pendingId) => pendingId !== id);
      });
    });
  }

  isDeleting(id) {
    return this.deleting.includes(id);
  }
}

module.exports = ProjectPlanSubtaskStore;
