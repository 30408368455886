const React = require('react');
const PropTypes = require('prop-types');
const T = require('@common/Typography/Typography.react');
const InlineCreator = require('@common/inputs/InlineCreator/InlineCreator.react');
const GanttPanelAccordion = require('../GanttPanelAccordion.react');
const GanttActivitiesPanelActions = require('./GanttActivitiesPanelActions.react');
const GanttActivity = require('../models/GanttActivity');

/**
 * An activity representing an area in a Gantt panel.
 * An area can contain tasks.
 *
 * @param {GanttActivity} area
 * @param createTask
 * @param onChange
 * @param showAreaDetail
 * @param deleteArea
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivitiesPanelArea = ({
  area,
  createTask,
  onChange,
  showAreaDetail,
  deleteArea,
  children,
}) => {
  function onExpandedChange(isExpanded) {
    onChange(area, isExpanded);
  }

  function showDetails(e) {
    e.stopPropagation();
    showAreaDetail(area.id);
  }

  function handleDeleteArea(e) {
    e.stopPropagation();
    deleteArea(area.id);
  }

  function saveTask(taskName) {
    const tasks = area.children;
    const lastTask = tasks[tasks.length - 1];
    const sort = lastTask ? lastTask.sort + 1 : 1;
    return createTask(area.id, { name: taskName, sort });
  }

  return (
    <GanttPanelAccordion label={
      <T weight={T.WEIGHTS.SEMIBOLD} uppercase>{area.name}</T>
    }
      className="wethod-gantt-panel__accordion--area"
      expanded={area.expanded}
      showDetails={showDetails}
      deleteActivity={handleDeleteArea}
      onChange={onExpandedChange}>
      <div>
        <div>
          {children}
        </div>
        <GanttActivitiesPanelActions>
          <InlineCreator placeholder="+ Task" save={saveTask} />
        </GanttActivitiesPanelActions>
      </div>
    </GanttPanelAccordion>
  );
};

GanttActivitiesPanelArea.defaultProps = {};

GanttActivitiesPanelArea.propTypes = {
  showAreaDetail: PropTypes.string.isRequired,
  deleteArea: PropTypes.string.isRequired,
  createTask: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  area: PropTypes.instanceOf(GanttActivity).isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = GanttActivitiesPanelArea;
