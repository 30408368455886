const moment = require('moment');
const $ = require('jquery');
const ItemModel = require('@models/Project');
const constants = require('./constants');
const TranslatorService = require('./services/TranslatorService');
const HttpService = require('../../../../services/HTTPService');
const SegmentModel = require('../../../../models/SectionSegment');
const MetadataModel = require('../../../../models/Metadata');
const WonLostFeedback = require('../../../../models/WonLostFeedback');

const itemDefaults = {
  project: {
    id: null,
    external_cost: 0,
    estimate: 0,
    archived: false,
    archived_date: null,
    visibility: 1,
    date_start: moment().date(1).format('YYYY-MM-DD'), // Start of current month
    duration: 1,
    timesheet_whitelist: false,
    whitelisted_employee: [],
    job_order_manual: true,
  },
  client: {
    id: null,
  },
  customer: {
    id: null,
  },
  budget: {
    id: null,
  },
  pm: {
    id: null,
  },
  account: {
    id: null,
  },
  project_label: {
    id: null,
  },
  project_type: {
    id: null,
  },
  opportunity_status: {
    id: null,
  },
  risk: {
    id: null,
  },
  metadata: [],
  _fields: [],
};

const hideModal = () => ({
  type: constants.HIDE_MODAL,
});

module.exports.hideModal = hideModal;

module.exports.showIntercompanyLinkModal = () => ({
  type: constants.SHOW_MODAL_INTERCOMPANY_LINK,
});

module.exports.showIntercompanyDetails = (projectId) => ({
  type: constants.SHOW_MODAL_INTERCOMPANY_DETAILS,
  projectId,
});

module.exports.showSaveSearchkModal = () => ({
  type: constants.SHOW_MODAL_SEARCH_SAVE,
});

module.exports.showExportSearchModal = () => ({
  type: constants.SHOW_MODAL_SEARCH_EXPORT,
});

module.exports.showShareSearchModal = () => ({
  type: constants.SHOW_MODAL_SEARCH_SHARE,
});

module.exports.showConfirmDeleteModal = (item) => ({
  type: constants.SHOW_MODAL_CONFIRM_DELETE,
  item,
});

module.exports.showConfirmSegmentDeleteModal = (segment) => ({
  type: constants.SHOW_MODAL_SEGMENT_DELETE,
  segment,
});

const showDeleteFailureModal = (project) => ({
  type: constants.SHOW_MODAL_DELETE_FAILURE,
  project,
});

module.exports.showConfirmArchiveModal = (item, archive) => ({
  type: constants.SHOW_MODAL_CONFIRM_ARCHIVE,
  item,
  archive,
});

const showArchiveFailureModal = (project) => ({
  type: constants.SHOW_MODAL_ARCHIVE_FAILURE,
  project,
});

module.exports.showShareProjectModal = (project) => ({
  type: constants.SHOW_MODAL_PROJECT_SHARE,
  project,
});

module.exports.showRequestReviewModal = (project) => ({
  type: constants.SHOW_MODAL_REVIEW_REQUEST,
  project,
});

const showReviewStartModal = (data) => ({
  type: constants.SHOW_MODAL_REVIEW_START,
  data,
});

const showEditFeedbackModal = (project, callback) => ({
  type: constants.SHOW_MODAL_EDIT_FEEDBACK,
  project,
  callback,
});

module.exports.showEditFeedbackModal = showEditFeedbackModal;

const showErrorModal = (message) => ({
  type: constants.SHOW_MODAL_SAVE_ERROR,
  message,
});

module.exports.showErrorModal = showErrorModal;

const showSnackbar = (data) => ({
  type: constants.SHOW_SNACKBAR,
  data,
});

module.exports.closeSnackbar = () => ({
  type: constants.CLOSE_SNACKBAR,
});

module.exports.showSnackbar = showSnackbar;

const getIntercompanyInvitationInfoFailure = () => ({
  type: constants.GET_INTERCOMPANY_INVITATION_INFO_FAILURE,
});

const getIntercompanyInvitationInfoSuccess = (info, projects) => ({
  type: constants.GET_INTERCOMPANY_INVITATION_INFO_SUCCESS,
  info,
  projects,
});

const getIntercompanyInvitationInfoRequest = () => ({
  type: constants.GET_INTERCOMPANY_INVITATION_INFO_REQUEST,
});

module.exports.getIntercompanyInvitationInfo = (token) => (dispatch) => {
  dispatch(getIntercompanyInvitationInfoRequest());

  const getInfoRequest = Wethod.request('pipeline:intercompany:invitation:info', { token });
  const getProjectsRequest = Wethod.request('pipeline:intercompany:connectable:projects');

  $.when(getInfoRequest, getProjectsRequest)
    .then((infoResponse, projectsResponse) => {
      dispatch(getIntercompanyInvitationInfoSuccess(infoResponse.data, projectsResponse.data));
    })
    .fail(() => {
      dispatch(getIntercompanyInvitationInfoFailure());
    });
};

const connectProjectFailure = (message) => ({
  type: constants.CONNECT_INTERCOMPANY_PROJECT_FAILURE,
  message,
});

const connectProjectSuccess = (projectId) => ({
  type: constants.CONNECT_INTERCOMPANY_PROJECT_SUCCESS,
  payload: { projectId },
});

const connectProjectRequest = () => ({
  type: constants.CONNECT_INTERCOMPANY_PROJECT_REQUEST,
});

module.exports.connectProject = (token, project) => (dispatch) => {
  dispatch(connectProjectRequest());

  const requestBody = {
    token,
    projectId: project.id,
  };
  Wethod.request('pipeline:intercompany:project:connect', requestBody)
    .then(() => {
      dispatch(connectProjectSuccess(project.id));
    })
    .fail((response) => {
      dispatch(connectProjectFailure(response.message));
    });
};

module.exports.showSegmentEdit = (segment) => ({
  type: constants.SHOW_SEGMENT_EDIT,
  segment,
});

module.exports.showSegmentCreate = () => ({
  type: constants.SHOW_SEGMENT_CREATE,
});

module.exports.showAdvancedSearch = () => ({
  type: constants.SHOW_ADVANCED_SEARCH,
});

const showSidebar = (item, winProbabilityThreshold) => ({
  type: constants.SHOW_SIDEBAR,
  item,
  winProbabilityThreshold,
});

const closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

module.exports.closeSidebar = closeSidebar;

const getItemsSuccess = (items, offset, requestId) => ({
  type: constants.GET_ITEMS_SUCCESS,
  items,
  offset,
  requestId,
});

const getItemsRequest = (offset, limit, filters, search, col, order, bu, requestId) => ({
  type: constants.GET_ITEMS_REQUEST,
  filters,
  search,
  offset,
  limit,
  bu,
  requestId,
  sortBy: {
    col,
    order,
  },
});

module.exports.getItems = (type, offset, limit, filters, search, orderBy,
  sort, units, probability = 90) => (dispatch) => {
  // Random ID to differentiate one request from the other
  const requestId = Math.floor(Math.random() * 1000);
  dispatch(getItemsRequest(offset, limit, filters, search, orderBy, sort, units, requestId));

  const defaultFilters = TranslatorService.getDefaultFilters(type, probability);

  const options = {
    filters: {
      ...defaultFilters,
      ...filters,
      search,
    },
    offset,
    limit,
    orderBy,
    sort,
    bu: units.join(),
  };

  $.when(ItemModel.getAll(options))
    .done((items) => dispatch(getItemsSuccess(items.toJSON(), offset, requestId)));
};

const saveSearchSuccess = () => ({
  type: constants.SEARCH_SAVE_SUCCESS,
});

const saveSearchRequest = () => ({
  type: constants.SEARCH_SAVE_REQUEST,
});

module.exports.saveSearch = (search, label, section = 'projects') => (dispatch) => {
  dispatch(saveSearchRequest());

  const options = {
    section,
    search,
    label,
  };

  const request = Wethod.request('save:pipeline:favourite:search', options);
  $.when(request).done(() => dispatch(saveSearchSuccess()));
};

const exportSearchRequest = () => ({
  type: constants.SEARCH_EXPORT_REQUEST,
});

module.exports.exportSearch = () => (dispatch) => {
  dispatch(exportSearchRequest());

  Wethod.request('get:pipeline:export');
};

const shareSearchRequest = () => ({
  type: constants.SEARCH_SHARE_REQUEST,
});

module.exports.shareSearch = (message, employeeId, path) => (dispatch) => {
  dispatch(shareSearchRequest());

  const options = {
    message,
    employeeId,
    path,
  };

  Wethod.request('send:pipeline:search:share', options);
};

const shareProjectRequest = () => ({
  type: constants.PROJECT_SHARE_REQUEST,
});

module.exports.shareProject = (message, employeeId, path, projectId) => (dispatch) => {
  dispatch(shareProjectRequest());

  const options = {
    message,
    employeeId,
    path,
    projectId,
  };

  Wethod.request('send:pipeline:project:shareProject', options);
};

module.exports.applyAdvancedSearch = (filters) => ({
  type: constants.APPLY_ADVANCED_SEARCH,
  filters,
});

module.exports.addProject = (bu, winProbabilityThreshold) => (dispatch) => {
  const project = {
    ...itemDefaults,
    business_unit: bu,
    project: {
      ...itemDefaults.project,
      probability: winProbabilityThreshold,
    },
  };
  dispatch(showSidebar(project, winProbabilityThreshold));
};

module.exports.addOpportunity = (opportunity, winProbabilityThreshold) => (dispatch) => {
  const defaults = {
    ...itemDefaults,
    project: {
      ...itemDefaults.project,
      probability: 10,
    },
  };
  dispatch(showSidebar({ ...defaults, ...opportunity }, winProbabilityThreshold));
};

const deleteProjectSuccess = (project) => ({
  type: constants.DELETE_PROJECT_SUCCESS,
  project,
});

const deleteProjectFailure = () => ({
  type: constants.DELETE_PROJECT_FAILURE,
});

const deleteProjectRequest = () => ({
  type: constants.DELETE_PROJECT_REQUEST,
});

module.exports.deleteProject = (item) => (dispatch) => {
  dispatch(deleteProjectRequest());

  const model = new Wethod.PipelineApp.Pipeline.ProjectModel(item.project);

  Wethod.request('delete:pipeline:project', model)
    .then(() => {
      dispatch(deleteProjectSuccess(item.project));
    })
    .fail(() => {
      dispatch(deleteProjectFailure());
      dispatch(showDeleteFailureModal(item));
    });
};

const archiveProjectSuccess = (item, archive, archivedDate) => ({
  type: constants.ARCHIVE_PROJECT_SUCCESS,
  item,
  archive,
  archivedDate,
});

const archiveProjectFailure = () => ({
  type: constants.ARCHIVE_PROJECT_FAILURE,
});

const archiveProjectRequest = () => ({
  type: constants.ARCHIVE_PROJECT_REQUEST,
});

module.exports.archiveProject = (item, archive) => (dispatch) => {
  dispatch(archiveProjectRequest());
  $.when(ItemModel.archive(item.project.id, archive))
    .done((response) => {
      dispatch(archiveProjectSuccess(response.toJSON(),
        archive,
        response.toJSON().project.archived_date));
    })
    .fail(() => {
      dispatch(archiveProjectFailure());
      dispatch(showArchiveFailureModal(item.project));
    });
};

const getLastReviewSuccess = (lastReview) => ({
  type: constants.GET_LAST_REVIEW_SUCCESS,
  lastReview,
});

const getLastReviewRequest = () => ({
  type: constants.GET_LAST_REVIEW_REQUEST,
});

module.exports.getLastReview = (projectId) => (dispatch) => {
  dispatch(getLastReviewRequest());
  const request = Wethod.request('get:project:review:last', { projectId });
  $.when(request)
    .done((response) => dispatch(getLastReviewSuccess(response)));
};

const createReviewSuccess = () => ({
  type: constants.CREATE_REVIEW_SUCCESS,
});

const createReviewRequest = () => ({
  type: constants.CREATE_REVIEW_REQUEST,
});

module.exports.createReview = (projectId) => (dispatch) => {
  dispatch(createReviewRequest());
  const request = Wethod.request('project:review:request', { projectId });
  $.when(request)
    .done((data) => {
      dispatch(createReviewSuccess());
      dispatch(showReviewStartModal(data));
    });
};

const getReasonWhySuccess = (reasons) => ({
  type: constants.GET_REASON_WHY_SUCCESS,
  reasons,
});

const getReasonWhyRequest = () => ({
  type: constants.GET_REASON_WHY_REQUEST,
});

module.exports.getReasonWhy = () => (dispatch) => {
  dispatch(getReasonWhyRequest());
  $.when(WonLostFeedback.getAll())
    .done((response) => dispatch(getReasonWhySuccess(response.toJSON())));
};

const saveReasonWhySuccess = (projectId, reasonId) => ({
  type: constants.SAVE_REASON_WHY_SUCCESS,
  projectId,
  reasonId,
});

const saveReasonWhyRequest = () => ({
  type: constants.SAVE_REASON_WHY_REQUEST,
});

module.exports.saveReasonWhy = (projectId, reasonId, callback) => (dispatch) => {
  dispatch(saveReasonWhyRequest());

  if (projectId && reasonId) {
    $.when(ItemModel.setReasonWhy(projectId, reasonId))
      .done(() => {
        dispatch(saveReasonWhySuccess(projectId, reasonId));
        if (callback) {
          callback(projectId, reasonId);
        }
      });
  } else {
    dispatch(saveReasonWhySuccess(projectId, reasonId));
    if (callback) {
      callback(projectId, reasonId);
    }
  }
};

module.exports.showSidebar = showSidebar;

const updateMetadataSuccess = (metadata, projectId) => ({
  type: constants.UPDATE_METADATA_SUCCESS,
  metadata,
  projectId,
});

const updateMetadataRequest = (metadata, projectId) => ({
  type: constants.UPDATE_METADATA_REQUEST,
  metadata,
  projectId,
});

const updateMetadata = (metadata, projectId) => (dispatch) => {
  dispatch(updateMetadataRequest(metadata, projectId));

  $.when(MetadataModel.save(metadata, projectId)).done(() => {
    dispatch(updateMetadataSuccess(metadata, projectId));
  });
};

const createMetadataSuccess = (metadata, projectId) => ({
  type: constants.CREATE_METADATA_SUCCESS,
  metadata,
  projectId,
});

const createMetadataRequest = (metadata, projectId) => ({
  type: constants.CREATE_METADATA_REQUEST,
  metadata,
  projectId,
});

const createMetadata = (metadata, projectId) => (dispatch) => {
  dispatch(createMetadataRequest(metadata, projectId));

  $.when(MetadataModel.save(metadata, projectId)).done((response) => {
    const formattedMetadata = {
      ...metadata,
      id: response.toJSON().id,
    };
    dispatch(createMetadataSuccess(formattedMetadata, projectId));
  });
};

const deleteMetadataSuccess = (metadata, projectId) => ({
  type: constants.DELETE_METADATA_SUCCESS,
  metadata,
  projectId,
});

const deleteMetadataRequest = (metadata, projectId) => ({
  type: constants.DELETE_METADATA_REQUEST,
  metadata,
  projectId,
});

const deleteMetadata = (metadata, projectId) => (dispatch) => {
  dispatch(deleteMetadataRequest(metadata, projectId));

  $.when(MetadataModel.delete(metadata, projectId))
    .done(() => dispatch(deleteMetadataSuccess(metadata, projectId)));
};

const moveItem = (project) => (dispatch) => {
  // Remove visible project
  dispatch(deleteProjectSuccess(project));
  // Show snackbar details
  dispatch(showSnackbar({ project }));
};

const saveItemFailure = (id) => ({
  type: constants.SAVE_ITEM_FAILURE,
  id,
});

const saveItemSuccess = (item) => ({
  type: constants.SAVE_ITEM_SUCCESS,
  item,
});

const saveItemRequest = (id) => ({
  type: constants.SAVE_ITEM_REQUEST,
  id,
});

/**
 * Save the updated item and the modified metadata.
 * Metadata needs a specific action to be saved, cause they are treated as different models, with a separated CRUD
 * @param id
 * @param changes
 * @param metadataToCreate // List of modified metadata that needs to be created
 * @param metadataToUpdate // List of modified metadata that needs to be updated
 * @param metadataToDelete // List of modified metadata that needs to be deleted
 * @param moveItemAfterSave // Flag to move item to correct section when save is completed
 * @returns {(function(*): void)|*}
 */
module.exports.saveItem = (id, changes, metadataToCreate = [],
  metadataToUpdate = [], metadataToDelete = [],
  moveItemAfterSave) => (dispatch) => {
  dispatch(saveItemRequest(id));

  $.when(ItemModel.save(id, changes))
    .done((response) => {
      metadataToDelete.forEach((metadata) => dispatch(deleteMetadata(metadata, id)));
      metadataToCreate.forEach((metadata) => dispatch(createMetadata(metadata, id)));
      metadataToUpdate.forEach((metadata) => dispatch(updateMetadata(metadata, id)));

      const updatedItem = response.toJSON();
      dispatch(saveItemSuccess(updatedItem));

      if (moveItemAfterSave) {
        dispatch(moveItem(updatedItem.project));
      }
    })
    .fail((response) => {
      if (response.code === 403) {
        dispatch(showErrorModal("You don't have permissions to edit this project"));
        dispatch(saveItemFailure(id));
        dispatch(closeSidebar());
      } else {
        dispatch(showErrorModal(response.message || 'An error occurred while saving the project'));
        dispatch(saveItemFailure(id));
        dispatch(closeSidebar());
      }
    });
};

const createItemSuccess = (item) => ({
  type: constants.CREATE_ITEM_SUCCESS,
  item,
});

const createItemRequest = () => ({
  type: constants.CREATE_ITEM_REQUEST,
});

const createItemFailure = () => ({
  type: constants.CREATE_ITEM_FAILURE,
});

/**
 * Create the new item with the modified metadata.
 * Metadata needs a specific action to be saved, cause they are treated as different models, with a separated CRUD
 * @param item
 * @param formattedChanges
 * @param metadataToCreate // List of modified metadata that needs to be created
 * @param metadataToUpdate // List of modified metadata that needs to be updated
 * @param metadataToDelete // List of modified metadata that needs to be deleted
 * @returns {(function(*): void)|*}
 */
module.exports.createItem = (item, formattedChanges, metadataToCreate = [],
  metadataToUpdate = [], metadataToDelete = []) => (dispatch) => {
  dispatch(createItemRequest());
  $.when(ItemModel.save(null, formattedChanges))
    .done((response) => {
      const newItem = response.toJSON();
      // Metadata are updated only when the project creation is done, since they need the new model id to be attached to
      metadataToDelete
        .forEach((metadata) => dispatch(deleteMetadata(metadata, newItem.project.id)));
      metadataToCreate
        .forEach((metadata) => dispatch(createMetadata(metadata, newItem.project.id)));
      metadataToUpdate
        .forEach((metadata) => dispatch(updateMetadata(metadata, newItem.project.id)));
      const updatedProject = {
        ...item,
        ...newItem,
        project: {
          ...item.project,
          ...newItem.project,
        },
      };
      dispatch(createItemSuccess(updatedProject, null));
    })
    .fail((response) => {
      if (response.code === 403) {
        dispatch(showErrorModal("You don't have permissions to create this project"));
        dispatch(createItemFailure());
        dispatch(closeSidebar());
      }
    });
};

const updateSegmentSuccess = (segment) => ({
  type: constants.UPDATE_SEGMENT_SUCCESS,
  segment,
});

const updateSegmentRequest = () => ({
  type: constants.UPDATE_SEGMENT_REQUEST,
});

module.exports.updateSegment = (segment) => (dispatch) => {
  dispatch(updateSegmentRequest(segment));
  const attributes = {
    name: segment.name,
    section: segment.section,
    filters: HttpService.buildQueryString(segment.filters),
  };
  const { id } = segment;

  $.when(SegmentModel.update(id, attributes)).done((updatedSegment) => dispatch(
    updateSegmentSuccess(updatedSegment),
  ));
};

const createSegmentSuccess = (segment) => ({
  type: constants.CREATE_SEGMENT_SUCCESS,
  segment,
});

const createSegmentRequest = () => ({
  type: constants.CREATE_SEGMENT_REQUEST,
});

module.exports.createSegment = (segment) => (dispatch) => {
  dispatch(createSegmentRequest(segment));

  const data = {
    ...segment,
    filters: HttpService.buildQueryString(segment.filters),
  };

  $.when(SegmentModel.create(data)).done((createdSegment) => dispatch(
    createSegmentSuccess(createdSegment),
  ));
};

const deleteSegmentRequest = () => ({
  type: constants.DELETE_SEGMENT_REQUEST,
});

const deleteSegmentSuccess = (segment) => ({
  type: constants.DELETE_SEGMENT_SUCCESS,
  segment,
});

module.exports.deleteSegment = (segment) => (dispatch) => {
  dispatch(deleteSegmentRequest());

  $.when(SegmentModel.delete(segment.id)).done(() => {
    dispatch(deleteSegmentSuccess(segment));
    dispatch(hideModal());
  });
};

module.exports.applySegment = (segment) => ({
  type: constants.APPLY_SEGMENT,
  segment,
});

module.exports.changeColumnConfiguration = (section, columns) => {
  Wethod.request('section:columns:update', section, columns);

  return {
    type: constants.COLUMN_CONFIGURATION_CHANGE,
    columns,
  };
};
