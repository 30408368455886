module.exports.CHANGE_ZOOM = 'CHANGE_ZOOM';

module.exports.FILTER_PEOPLE = 'FILTER_PEOPLE';

module.exports.GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
module.exports.GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';

module.exports.GET_PROJECT_AVAILABILITY_REQUEST = 'GET_PROJECT_AVAILABILITY_REQUEST';
module.exports.GET_PROJECT_AVAILABILITY_SUCCESS = 'GET_PROJECT_AVAILABILITY_SUCCESS';
module.exports.GET_PROJECT_AVAILABILITY_FAILURE = 'GET_PROJECT_AVAILABILITY_FAILURE';

module.exports.FILTER_PROJECT_LEVELS_BY_PRICELIST = 'FILTER_PROJECT_LEVELS_BY_PRICELIST';
module.exports.FILTER_PEOPLE_BY_PRICELIST = 'FILTER_PEOPLE_BY_PRICELIST';

module.exports.EDIT_PLAN_REQUEST = 'EDIT_PLAN_REQUEST';
module.exports.EDIT_PLAN_SUCCESS = 'EDIT_PLAN_SUCCESS';
module.exports.EDIT_PLAN_FAILURE = 'EDIT_PLAN_FAILURE';

module.exports.CLOSE_PROJECT_INFO = 'CLOSE_PROJECT_INFO';

module.exports.CLOSE_MODAL = 'CLOSE_MODAL';

module.exports.SHOW_MODAL = 'SHOW_MODAL';

module.exports.SELECT_PEOPLE_FILTER = 'SELECT_PEOPLE_FILTER';
module.exports.DESELECT_PEOPLE_FILTER = 'DESELECT_PEOPLE_FILTER';

module.exports.CLEAR_PEOPLE_FILTERS = 'CLEAR_PEOPLE_FILTERS';
module.exports.SEARCH_KEYWORD_CHANGE = 'SEARCH_KEYWORD_CHANGE';

module.exports.SAVE_BUDGET_CONVERSION_REQUEST = 'SAVE_BUDGET_CONVERSION_REQUEST';
module.exports.SAVE_BUDGET_CONVERSION_SUCCESS = 'SAVE_BUDGET_CONVERSION_SUCCESS';
module.exports.SAVE_BUDGET_CONVERSION_FAILURE = 'SAVE_BUDGET_CONVERSION_FAILURE';

module.exports.GET_PEOPLE_REQUEST = 'GET_PEOPLE_REQUEST';
module.exports.GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';

module.exports.GET_AVAILABLE_PROJECTS_REQUEST = 'GET_AVAILABLE_PROJECTS_REQUEST';
module.exports.GET_AVAILABLE_PROJECTS_SUCCESS = 'GET_AVAILABLE_PROJECTS_SUCCESS';

module.exports.GET_AVAILABLE_PEOPLE_FILTERS_REQUEST = 'GET_AVAILABLE_PEOPLE_FILTERS_REQUEST';
module.exports.GET_AVAILABLE_PEOPLE_FILTERS_SUCCESS = 'GET_AVAILABLE_PEOPLE_FILTERS_SUCCESS';

module.exports.GET_PLANNING_SETTINGS_SUCCESS = 'GET_PLANNING_SETTINGS_SUCCESS';

module.exports.GET_AVAILABLE_PRICELISTS_SUCCESS = 'GET_AVAILABLE_PRICELISTS_SUCCESS';

module.exports.SET_SELECTED_PRICELIST = 'SET_SELECTED_PRICELIST';

module.exports.IS_INTERNATIONAL_CALENDAR_REQUEST = 'IS_INTERNATIONAL_CALENDAR_REQUEST';
module.exports.IS_INTERNATIONAL_CALENDAR_SUCCESS = 'IS_INTERNATIONAL_CALENDAR_SUCCESS';

module.exports.GET_FTE_REQUEST = 'GET_FTE_REQUEST';
module.exports.GET_FTE_SUCCESS = 'GET_FTE_SUCCESS';

module.exports.TOGGLE_RECURRING_MODE = 'TOGGLE_RECURRING_MODE';

module.exports.RECURRING_REQUEST = 'RECURRING_REQUEST';
module.exports.RECURRING_SUCCESS = 'RECURRING_SUCCESS';
module.exports.RECURRING_ADD_FAILURE = 'RECURRING_ADD_FAILURE';

module.exports.CONFIRM_WEEKEND_PLANNING = 'CONFIRM_WEEKEND_PLANNING';
module.exports.EXCLUDE_WEEKEND_PLANNING = 'EXCLUDE_WEEKEND_PLANNING';
module.exports.CONFIRM_HOLIDAY_PLANNING = 'CONFIRM_HOLIDAY_PLANNING';
module.exports.CONFIRM_CAPACITY_PLANNING = 'CONFIRM_CAPACITY_PLANNING';

module.exports.EXCLUDE_HOLIDAY_PLANNING = 'EXCLUDE_HOLIDAY_PLANNING';

module.exports.SET_RECURRING_MODE_TIMESTAMP = 'SET_RECURRING_MODE_TIMESTAMP';

module.exports.FAILURE_REASONS = {
  MISSING_APPROVED_BUDGET_VERSION: 'MISSING_APPROVED_BUDGET_VERSION',
  MISSING_PERMISSIONS: 'MISSING_PERMISSIONS',
};

module.exports.GET_HOLIDAYS_REQUEST = 'GET_HOLIDAYS_REQUEST';
module.exports.GET_HOLIDAYS_SUCCESS = 'GET_HOLIDAYS_SUCCESS';
