const React = require('react');
const PropTypes = require('prop-types');
const InlineCreator = require('@common/inputs/InlineCreator/InlineCreator.react');
const GanttPanel = require('../GanttPanel.react');
const GanttActivitiesPanelActions = require('./GanttActivitiesPanelActions.react');
const GanttActivitiesPanelArea = require('./GanttActivitiesPanelArea.react');
const GanttActivitiesPanelTask = require('./GanttActivitiesPanelTask.react');
const GanttActivitiesPanelSubtask = require('./GanttActivitiesPanelSubtask.react');
const GanttActivity = require('../models/GanttActivity');

/**
 * A Gantt panel used to list activities where the first level is considered an area, the second
 * level is considered a task and the third level a subtask.
 * @param navigatePast
 * @param navigateFuture
 * @param navigateToday
 * @param {GanttActivity[]} activities
 * @param createArea
 * @param createTask
 * @param createSubtask
 * @param createMilestone
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivitiesPanel = ({
  navigatePast,
  navigateFuture,
  navigateToday,
  activities,
  createArea,
  createTask,
  createSubtask,
  onToggleActivityAccordion,
  showAreaDetail,
  showTaskDetail,
  showSubtaskDetail,
  deleteArea,
  deleteTask,
  deleteSubtask,
  isCreatingArea,
}) => {
  function saveArea(name) {
    const lastArea = activities[activities.length - 1];
    const sort = lastArea ? lastArea.sort + 1 : 1;
    return createArea({ name, sort });
  }
  /**
   * @param {GanttActivity[]} subtasks
   * @return {*}
   */
  const getSubtasks = (subtasks) => subtasks
    .map((subtask) => (
      <GanttActivitiesPanelSubtask key={subtask.id}
        deleteSubtask={deleteSubtask}
        subtask={subtask}
        showSubtaskDetail={showSubtaskDetail} />
    ));

  /**
   * @param {GanttActivity[]} tasks
   * @return {*}
   */
  const getTasks = (tasks) => tasks
    .map((task) => (
      <GanttActivitiesPanelTask key={task.id}
        onChange={onToggleActivityAccordion}
        task={task}
        createSubtask={createSubtask}
        deleteTask={deleteTask}
        showTaskDetail={showTaskDetail}>
        {getSubtasks(task.children)}
      </GanttActivitiesPanelTask>
    ));

  const getAreas = () => activities.map((area) => (
    <GanttActivitiesPanelArea key={area.id}
      deleteArea={deleteArea}
      area={area}
      onChange={onToggleActivityAccordion}
      createTask={createTask}
      showAreaDetail={showAreaDetail}>
      {getTasks(area.children)}
    </GanttActivitiesPanelArea>
  ));

  return (
    <GanttPanel navigateToday={navigateToday}
      navigateFuture={navigateFuture}
      navigatePast={navigatePast}>
      <div className="wethod-gantt-panel-activities">
        {getAreas()}
      </div>
      <GanttActivitiesPanelActions>
        <InlineCreator placeholder="+ Area" isSaving={isCreatingArea} save={saveArea} />
      </GanttActivitiesPanelActions>
    </GanttPanel>
  );
};

GanttActivitiesPanel.defaultProps = {};

GanttActivitiesPanel.propTypes = {
  navigatePast: PropTypes.func.isRequired,
  navigateFuture: PropTypes.func.isRequired,
  navigateToday: PropTypes.func.isRequired,
  activities: PropTypes.arrayOf(GanttActivity).isRequired,
  createArea: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  createSubtask: PropTypes.func.isRequired,
  onToggleActivityAccordion: PropTypes.func.isRequired,
  showAreaDetail: PropTypes.func.isRequired,
  showTaskDetail: PropTypes.func.isRequired,
  showSubtaskDetail: PropTypes.func.isRequired,
  deleteArea: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  deleteSubtask: PropTypes.func.isRequired,
  isCreatingArea: PropTypes.bool.isRequired,
};

module.exports = GanttActivitiesPanel;
