const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const NavigationButton = require('@common/NavigationButton.react');
const Modal = require('@common/Modal/Modal.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');

const {
  inject,
  observer,
} = require('mobx-react');

const InitializationModal = ({
  closeModal,
  initializing,
  initializeFromScratch,
}) => {
  const feedback = initializing ? 'Creating...' : '';

  function onNewClick() {
    initializeFromScratch();
  }

  return (
    <Modal title="Create project plan" onClose={closeModal}>
      <ModalContent>
        <ModalBody>
          <NavigationButton key="new"
            title="New"
            subtitle="Create a new plan from scratch"
            onClick={onNewClick} />
        </ModalBody>
        <ModalFooter feedback={feedback} />
      </ModalContent>
    </Modal>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  initializing: stores.store.projectStore.initializingPlan,
  initializeFromScratch: () => stores.store.projectStore.initializePlanFromScratch(),
});

module.exports = inject(storeMapper)(observer(InitializationModal));
