/* eslint-disable react/jsx-no-bind */
const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const GanttCalendar = require('./GanttCalendar.react');
const GanttActivitiesPanel = require('./GanttActivitiesPanel/GanttActivitiesPanel.react');
const GanttActivities = require('./GanttActivities/GanttActivities.react');
const GanttActivity = require('./models/GanttActivity');
const GanttModel = require('./models/Gantt');

require('./style.scss');

class Gantt extends React.Component {
  /**
   * Return all days between given from and to.
   * @param {moment} from
   * @param {moment} to
   * @return {moment[]}
   */
  static generateDays(from, to) {
    const days = [];
    while (from.isSameOrBefore(to, 'day')) {
      days.push(from.clone()); // Clone to avoid mutation
      from.add(1, 'day');
    }
    return days;
  }

  constructor(props) {
    super(props);

    this.dayWidth = {
      [GanttModel.PRECISION_DAY]: 40,
      [GanttModel.PRECISION_WEEK]: 20,
      [GanttModel.PRECISION_MONTH]: 7,
    };

    this.rowHeight = 32;
    this.visibleDaysAmount = Math.round(this.props.width / this.getColumnWidth());

    this.state = {
      days: Gantt.generateDays(
        props.from.clone().subtract(this.visibleDaysAmount, 'days'),
        props.from.clone().add(this.visibleDaysAmount, 'days'),
      ),
      expandedActivities: [],
    };

    this.ref = null;
    this.today = moment();

    this.setRef = this.setRef.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.getDayPosition = this.getDayPosition.bind(this);
    this.toggleActivityAccordion = this.toggleActivityAccordion.bind(this);
  }

  componentDidMount() {
    // Make today the first visible day
    this.scrollToDay(this.today);
  }

  onScroll(e) {
    const { scrollLeft } = e.target;
    const scrolledToEnd = scrollLeft + this.getWidth() === this.getContentWidth();
    const scrolledToBeginning = scrollLeft === 0;

    if (scrolledToBeginning) {
      this.addPast();
    }
    if (scrolledToEnd) {
      this.addFuture();
    }
  }

  /**
   * Return the visible calendar width.
   * @return {number|undefined}
   */
  getWidth() {
    return this?.ref.clientWidth;
  }

  /**
   * Return the total width of the calendar content.
   * @return {number|undefined}
   */
  getContentWidth() {
    return this?.ref.scrollWidth;
  }

  /**
   * Return index of the given date in the days list.
   * @param {moment} date
   * @return {number}
   */
  getDayPosition(date) {
    return this.state.days.findIndex((day) => date.isSame(day, 'day'));
  }

  getColumnWidth() {
    return this.dayWidth[this.props.precision];
  }

  getActivities(activities) {
    return activities
      .map((activity) => {
      // eslint-disable-next-line no-param-reassign
        activity.expanded = this.isExpandedActivity(activity.id, activity.type);
        // eslint-disable-next-line no-param-reassign
        activity.children = this.getActivities(activity.children);
        return activity;
      });
  }

  setRef(ref) {
    this.ref = ref;
  }

  /**
   * Return true if activity with given id and type is expanded.
   * @param {number} id
   * @param {string} type
   * @return {boolean}
   */
  isExpandedActivity(id, type) {
    return this.state.expandedActivities
      .some((activity) => activity.id === id && activity.type === type);
  }

  /**
   * Add future days.
   */
  addFuture() {
    const lastDay = this.state.days[this.state.days.length - 1];
    const from = lastDay.clone().add(1, 'days');
    const to = from.clone().add(this.visibleDaysAmount, 'days');
    const futureDays = Gantt.generateDays(from, to);
    this.setState((state) => ({ days: state.days.concat(futureDays) }));
  }

  /**
   * Add past days.
   */
  addPast() {
    const firstDay = this.state.days[0];
    const to = firstDay.clone().subtract(1, 'days');
    const from = to.clone().subtract(this.visibleDaysAmount, 'days');
    const pastDays = Gantt.generateDays(from, to);
    this.setState((state) => ({ days: pastDays.concat(state.days) }), () => {
      // Scroll on the first day that was visible before adding past.
      this.scrollToDay(firstDay);
    });
  }

  /**
   *
   * @param {{id:number, type:string}} activity
   * @param {boolean} expanded
   */
  toggleActivityAccordion(activity, expanded) {
    this.setState((state) => {
      let expandedActivities = state.expandedActivities
        .filter((expandedActivity) => !(activity.id === expandedActivity.id
          && activity.type === expandedActivity.type));
      if (expanded) {
        expandedActivities = expandedActivities.concat(activity);
      }
      return { expandedActivities };
    });
  }

  /**
   * Scroll to make given day the first one visible.
   * @param {moment} day
   */
  scrollToDay(day) {
    this.ref.scrollLeft = this.getDayPosition(day) * this.getColumnWidth();
  }

  render() {
    const activities = this.getActivities(this.props.activities);

    return (
      <div className="wethod-gantt" ref={this.setRef} onScroll={this.onScroll}>
        <div className="wethod-gantt-content">
          <GanttActivitiesPanel navigateToday={() => this.scrollToDay(this.today)}
            onToggleActivityAccordion={this.toggleActivityAccordion}
            createArea={this.props.createArea}
            createTask={this.props.createTask}
            createSubtask={this.props.createSubtask}
            navigatePast={() => this.scrollToDay(this.state.days[0])}
            navigateFuture={() => this.scrollToDay(this.state.days[this.state.days.length - 1])}
            activities={activities}
            showAreaDetail={this.props.showAreaDetail}
            showTaskDetail={this.props.showTaskDetail}
            deleteArea={this.props.deleteArea}
            deleteTask={this.props.deleteTask}
            deleteSubtask={this.props.deleteSubtask}
            showSubtaskDetail={this.props.showSubtaskDetail}
            isCreatingArea={this.props.isCreatingArea} />
          <GanttCalendar columnWidth={this.getColumnWidth()}
            days={this.state.days}
            precision={this.props.precision}>
            <GanttActivities columnWidth={this.getColumnWidth()}
              showAreaDetail={this.props.showAreaDetail}
              showTaskDetail={this.props.showTaskDetail}
              showSubtaskDetail={this.props.showSubtaskDetail}
              activities={activities}
              getDayPosition={this.getDayPosition} />
          </GanttCalendar>
        </div>
      </div>
    );
  }
}

Gantt.defaultProps = {
  from: moment(),
  precision: GanttModel.PRECISION_DAY,
};

Gantt.propTypes = {
  width: PropTypes.number.isRequired,
  from: PropTypes.instanceOf(moment),
  /**
   * List of sorted activities. Each activity can have children activities.
   */
  activities: PropTypes.arrayOf(PropTypes.instanceOf(GanttActivity)).isRequired,
  createArea: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  createSubtask: PropTypes.func.isRequired,
  showAreaDetail: PropTypes.func.isRequired,
  showTaskDetail: PropTypes.func.isRequired,
  showSubtaskDetail: PropTypes.func.isRequired,
  deleteArea: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  deleteSubtask: PropTypes.func.isRequired,
  precision: PropTypes.oneOf([
    GanttModel.PRECISION_DAY,
    GanttModel.PRECISION_WEEK,
    GanttModel.PRECISION_MONTH,
  ]),
  isCreatingArea: PropTypes.bool.isRequired,
};

module.exports = Gantt;
