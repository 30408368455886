const React = require('react');
// eslint-disable-next-line import/no-extraneous-dependencies
const { Provider } = require('mobx-react');
const ProjectPlan = require('./components/ProjectPlan.react');
const Store = require('./stores/ProjectPlanRootStore');

require('./style.scss');

module.exports = ({ projectId }) => (
  <Provider store={new Store(projectId)}>
    <ProjectPlan />
  </Provider>
);
