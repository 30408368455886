const React = require('react');
const PropTypes = require('prop-types');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const MoreOptionsButton = require('@common/MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsItem = require('@common/Menu/ListMenu/MenuItem.react');
const Description = require('./ProjectInfoHeaderDescription.react');
const InfoHeader = require('../InfoHeader.react');

const ProjectInfoHeader = ({
  id,
  name,
  clientName,
  jocColor,
  additionalActions,
  descriptionSide,
}) => {
  const defaultActions = [
    <IconLink key="pipeline"
      label="Pipeline"
      href={`#pipeline/projects?id=${id}`}
      target="_blank">
      <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
    </IconLink>,
    <IconLink key="budget"
      label="Budget"
      href={`#pipeline/budget/${id}`}
      target="_blank">
      <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
    </IconLink>,
    <IconLink key="canvas"
      label="Canvas"
      href={`#project/${id}/canvas`}
      target="_blank">
      <span className="wethod-icon wethod-icon-canvas wethod-icon-canvas--black" />
    </IconLink>,
    <IconLink key="planning"
      label="Planning"
      href={`#planning/people?project_id=${id}`}
      target="_blank">
      <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
    </IconLink>,
    <IconLink key="report"
      label="Report"
      href={`#project/${id}/report`}
      target="_blank">
      <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
    </IconLink>,
    <MoreOptionsButton key="more-options">
      <MoreOptionsItem href={`#planning/production-plan?project_id:${id}`}>Production plan</MoreOptionsItem>
      <MoreOptionsItem href={`#timeline/${id}`}>Timeline</MoreOptionsItem>
      <MoreOptionsItem href={`/#project-status/${id}`}>Project status</MoreOptionsItem>
    </MoreOptionsButton>,
  ];

  const description = (
    <Description name={name}
      client={clientName}
      joc={jocColor}
      side={descriptionSide} />
  );

  const actions = [
    ...defaultActions,
  ];

  return (
    <InfoHeader description={description}
      actions={additionalActions.concat(actions)} />
  );
};

ProjectInfoHeader.defaultProps = {
  additionalActions: [],
  descriptionSide: null,
};

ProjectInfoHeader.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  jocColor: PropTypes.string.isRequired,
  additionalActions: PropTypes.arrayOf(PropTypes.node),
  descriptionSide: PropTypes.node,
};

module.exports = ProjectInfoHeader;
