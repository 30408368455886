const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const {
  observer,
  inject,
} = require('mobx-react');
const ProjectPlanArea = require('../../models/ProjectPlanArea');
const ProjectPlanTask = require('../../models/ProjectPlanTask');
const ProjectPlanSubtask = require('../../models/ProjectPlanSubtask');

/**
 * Delete activity confirmation prompt.
 * @param onClose
 * @param {string} title
 * @param {ProjectPlanActivity} activity
 * @param deleteActivity
 * @param {boolean} isDeleting True if given activity id is waiting for delete action to finish
 * @return {JSX.Element}
 * @constructor
 */
const DeleteActivityModal = ({
  onClose, title, activity, deleteArea,
  deleteTask, deleteSubtask, isDeletingArea, isDeletingTask, isDeletingSubtask,
}) => {
  const deleteActivity = () => {
    if (activity instanceof ProjectPlanArea) {
      return deleteArea(activity.id);
    }
    if (activity instanceof ProjectPlanTask) {
      return deleteTask(activity.id);
    }
    if (activity instanceof ProjectPlanSubtask) {
      return deleteSubtask(activity.id);
    }
    throw Error('Activity has an invalid type.');
  };

  const isDeleting = () => {
    if (activity instanceof ProjectPlanArea) {
      return isDeletingArea(activity.id);
    }
    if (activity instanceof ProjectPlanTask) {
      return isDeletingTask(activity.id);
    }
    if (activity instanceof ProjectPlanSubtask) {
      return isDeletingSubtask(activity.id);
    }
    throw Error('Activity has an invalid type.');
  };

  function onOkClick() {
    deleteActivity().done(onClose);
  }

  const feedback = isDeleting() ? 'Deleting...' : '';

  return (
    <Modal onClose={onClose} title={title}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>{`Are you sure you want to delete “${activity.name}”?`}</ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={feedback}>
          <ModalFooterAction onClick={onClose} disabled={isDeleting()}>Cancel</ModalFooterAction>
          <ModalFooterAction onClick={onOkClick} disabled={isDeleting()}>Yes</ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  deleteArea: (id) => stores.store.areasStore.deleteArea(id),
  deleteTask: (id) => stores.store.tasksStore.deleteTask(id),
  deleteSubtask: (id) => stores.store.subtasksStore.deleteSubtask(id),
  isDeletingTask: (id) => stores.store.tasksStore.isDeleting(id),
  isDeletingArea: (id) => stores.store.areasStore.isDeleting(id),
  isDeletingSubtask: (id) => stores.store.subtasksStore.isDeleting(id),
});

module.exports = inject(storeMapper)(observer(DeleteActivityModal));
