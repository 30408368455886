const React = require('react');
const ProjectInfoHeader = require('@common/InfoHeader/ProjectInfoHeader/ProjectInfoHeader.react');
const {
  inject,
  observer,
} = require('mobx-react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const SectionContent = require('@common/Section/SectionContent.react');
const View = require('./View.react');
const InitializationModal = require('./InitializationModal.react');
const Actions = require('./ProjectPlanActions.react');

class ProjectPlanBody extends React.Component {
  componentDidMount() {
    if (!this.props.initialized) {
      modalService.openModal(<InitializationModal />);
    }
  }

  componentDidUpdate(prevProps) {
    const projectPlanInitialized = !prevProps.initialized && this.props.initialized;
    if (projectPlanInitialized) {
      modalService.closeModal();
    }
  }

  render() {
    return (
      <SectionContent>
        <ProjectInfoHeader jocColor={this.props.project.project_type.color}
          clientName={this.props.project.client.corporate_name}
          name={this.props.project.name}
          id={this.props.project.id} />
        <Actions />
        <View />
      </SectionContent>
    );
  }
}

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const mapper = (stores) => ({
  initialized: stores.store.projectStore.project.project_plan_initialized,
  project: stores.store.projectStore.project,
});

module.exports = inject(mapper)(observer(ProjectPlanBody));
