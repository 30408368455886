const React = require('react');
const IconButton = require('@common/TooltipFixed/IconButtonTooltip.react');
const ProjectInfoHeader = require('@common/InfoHeader/ProjectInfoHeader/ProjectInfoHeader.react');

module.exports = class Info extends React.Component {
  constructor(props) {
    super(props);

    this.handleReviewClick = this.handleReviewClick.bind(this);
  }

  handleReviewClick() {
    const data = { projectId: this.props.id };
    this.props.showModal(data);
  }

  getReviewButton() {
    if (this.props.isReviewEnabled && this.props.canReview) {
      return (
        <IconButton label="Project and team review"
          onClick={this.handleReviewClick}>
          <span className="wethod-icon wethod-icon-star wethod-icon-star--black" />
        </IconButton>
      );
    }
    return null;
  }

  render() {
    return (
      <ProjectInfoHeader jocColor={this.props.project_type.color}
        clientName={this.props.client.corporate_name}
        name={this.props.name}
        id={this.props.id}
        additionalActions={[this.getReviewButton()]} />
    );
  }
};
