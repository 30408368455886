'use strict';

/* eslint-disable eqeqeq */
Wethod.module('PlanningApp.Planning', function (Planning, Wethod, Backbone, Marionette, $, _) {
  // recupero dati planning in un dato intervallo di tempo
  this.PlanningModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/planningboard/?from=' + this.options.dateStart + '&to=' + this.options.dateEnd;
    },
  });

  this.PlanningProjectModel = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/planning-projects/';
    },
  });

  this.PlanningEventTypesModel = Wethod.Utility.BaseModel.extend();

  this.PlanningEventTypes = Wethod.Utility.BaseCollection.extend({
    model: Planning.PlanningEventTypesModel,
    url: function () {
      return APIURL + '/event-type/';
    },
  });

  this.Projects = Wethod.Utility.BaseCollection.extend({
    url: function () {
      return APIURL + '/planningboard/hint';
    },
  });

  this.Filters = Wethod.Utility.BaseModel.extend({
    urlRoot: function () {
      return APIURL + '/planningboard/filters';
    },
  });

  // recupero i dati di planningboard (salvataggio cancellazione planning)
  this.PlanningboardModel = Wethod.Utility.BaseModel.extend({
    urlRoot: APIURL + '/planningboard/',
  });

  var API = {
    getPlanning: function (data) {
      var record = new Planning.PlanningModel(data);
      var defer = $.Deferred();

      record.fetch({
        success: function (responseData) {
          defer.resolve(responseData);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    getPlanningProjects: function () {
      var record = new Planning.PlanningProjectModel();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    getPlanningEventTypes: function () {
      var record = new Planning.PlanningEventTypes();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    getProjectsList: function () {
      var record = new Planning.Projects();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    getFilters: function () {
      var record = new Planning.Filters();
      var defer = $.Deferred();

      record.fetch({
        success: function (data) {
          defer.resolve(data);
        },
        error: function (response) {
          defer.reject(response);
        },
      });

      return defer.promise();
    },
    repeatPlanningAdd: function (force, includeHolidays, includeWeekends, data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/planningboard/repeat-add?force=' + force + '&includeHolidays=' + includeHolidays + '&includeWeekends=' + includeWeekends,
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        data: JSON.stringify(data),
      }).done(function (response) {
        if (response.code != 200) {
          defer.reject(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    repeatPlanningDelete: function (data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/planningboard/repeat-delete',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        data: JSON.stringify(data),
      }).done(function (response) {
        if (response.code != 200) {
          defer.reject(response);
        } else {
          defer.resolve(response);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    updatePlanning: function (data) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        crossDomain: true,
        url: APIURL + '/planningboard/',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        data: JSON.stringify(data),
      }).done(function (response) {
        if (response.code != 200) {
          defer.reject(undefined);
        } else {
          defer.resolve(response);
        }
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    deletePlanningboard: function (data) {
      var defer = $.Deferred();

      // rimuovo tutte le parti superfrue al salvataggio
      _.each(data.plannings, function (planning) {
        delete planning.DOMel;
        delete planning.projectName;
      });

      $.ajax({
        method: 'DELETE',
        crossDomain: true,
        url: APIURL + '/planningboard/',
        xhrFields: {
          withCredentials: true,
        },
        dataType: 'json',
        data: JSON.stringify(data),
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
    getPlanningPeople: function () {
      var defer = $.Deferred();

      $.ajax({
        method: 'GET',
        crossDomain: true,
        url: APIURL + '/planningboard/employees',
        xhrFields: {
          withCredentials: true,
        },
      }).done(function (response) {
        defer.resolve(response);
      }).fail(function () {
        defer.reject(undefined);
      });

      return defer.promise();
    },
  };

  Wethod.reqres.setHandler('get:planning', function (data) {
    return API.getPlanning(data);
  });

  Wethod.reqres.setHandler('get:planning:people', function () {
    return API.getPlanningPeople();
  });

  Wethod.reqres.setHandler('get:planning:projects', function (data) {
    return API.getPlanningProjects(data);
  });

  // Usato anche in canvas
  Wethod.reqres.setHandler('get:planning:event:types', function () {
    return API.getPlanningEventTypes();
  });

  Wethod.reqres.setHandler('get:planning:projects-list', function () {
    return API.getProjectsList();
  });
  Wethod.reqres.setHandler('get:planning:filters', function () {
    return API.getFilters();
  });
  Wethod.reqres.setHandler('update:planning', function (data) {
    return API.updatePlanning(data);
  });
  Wethod.reqres.setHandler('repeat:planning:add', function (force, includeHolidays, includeWeekends, data) {
    return API.repeatPlanningAdd(force, includeHolidays, includeWeekends, data);
  });
  Wethod.reqres.setHandler('repeat:planning:delete', function (data) {
    return API.repeatPlanningDelete(data);
  });
});
