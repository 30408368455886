const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const GanttHeaderMonthLabel = ({ days }) => (
  <div className="wethod-gantt-header__label wethod-gantt-header__label--month">
    {days[0].format('MMMM YYYY')}
  </div>
);

GanttHeaderMonthLabel.defaultProps = {};

GanttHeaderMonthLabel.propTypes = {
  days: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
};

module.exports = GanttHeaderMonthLabel;
