const React = require('react');
const PropTypes = require('prop-types');
const GanttActivitiesPanelActions = require('@common/Gantt/GanttActivitiesPanel/GanttActivitiesPanelActions.react');
const GanttPanelAccordion = require('@common/Gantt/GanttPanelAccordion.react');
const T = require('@common/Typography/Typography.react');
const InlineCreator = require('@common/inputs/InlineCreator/InlineCreator.react');
const GanttActivity = require('../models/GanttActivity');

/**
 * An activity representing a task in a Gantt panel.
 * A task can contain subtasks ad is always contained in a panel area.
 * @param {GanttActivity} task
 * @param createSubtask
 * @param onChange
 * @param showTaskDetail
 * @param deleteTask
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivitiesPanelTask = ({
  task, createSubtask, onChange, showTaskDetail, deleteTask, children,
}) => {
  function onExpandedChange(isExpanded) {
    onChange(task, isExpanded);
  }

  function showDetails(e) {
    e.stopPropagation();
    showTaskDetail(task.id);
  }

  function handleDeleteTask(e) {
    e.stopPropagation();
    deleteTask(task.id);
  }

  function saveSubtask(subtaskName) {
    const subtasks = task.children;
    const lastSubtask = subtasks[subtasks.length - 1];
    const sort = lastSubtask ? lastSubtask.sort + 1 : 1;
    return createSubtask(task.id, { name: subtaskName, sort });
  }

  return (
    <GanttPanelAccordion label={<T weight={T.WEIGHTS.SEMIBOLD}>{task.name}</T>}
      showDetails={showDetails}
      className="wethod-gantt-panel__accordion--task"
      expanded={task.expanded}
      deleteActivity={handleDeleteTask}
      onChange={onExpandedChange}>
      <div className="wethod-gantt-panel__accordion--task-content">
        <div>
          {children}
        </div>
        <GanttActivitiesPanelActions>
          <InlineCreator placeholder="+ Subtask" save={saveSubtask} />
        </GanttActivitiesPanelActions>
      </div>
    </GanttPanelAccordion>
  );
};

GanttActivitiesPanelTask.defaultProps = {};

GanttActivitiesPanelTask.propTypes = {
  task: PropTypes.instanceOf(GanttActivity).isRequired,
  children: PropTypes.node.isRequired,
  createSubtask: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showTaskDetail: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
};

module.exports = GanttActivitiesPanelTask;
