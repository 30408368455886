const ProjectStore = require('./ProjectStore');
const ProjectPlanAreaStore = require('./ProjectPlanAreaStore');
const ProjectPlanTaskStore = require('./ProjectPlanTaskStore');
const ProjectPlanSubtaskStore = require('./ProjectPlanSubtaskStore');
const AuthorizationStore = require('./AuthorizationStore');
const UiStore = require('./UiStore');

/**
 * @type {ProjectPlanRootStore}
 */
module.exports = class ProjectPlanRootStore {
  constructor(projectId) {
    this.projectId = parseInt(projectId);

    this.authorizationStore = new AuthorizationStore();
    this.projectStore = new ProjectStore(this);
    this.subtasksStore = new ProjectPlanSubtaskStore(this);
    this.tasksStore = new ProjectPlanTaskStore(this);
    this.areasStore = new ProjectPlanAreaStore(this);
    this.uiStore = new UiStore();

    this.authorizationStore.loadPermissions('project_plan');
    this.projectStore.loadProject(projectId);
  }
};
