/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Navigator = require('@common/Gantt/GanttNavigator.react');

module.exports = class CalendarActions extends React.Component {
  render() {
    return (
      <div className="planning-calendar__header-track planning-calendar__header-track--actions">
        <div className="planning-calendar__header-track--actions-content">
          <Navigator onTodayClick={this.props.onTodayClick}
            onPastClick={this.props.onPrevClick}
            onFutureClick={this.props.onNextClick} />
        </div>
      </div>
    );
  }
};
