const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const GanttHeaderWeekLabel = ({ days }) => {
  const firstDay = days[0];
  const lastDay = days[days.length - 1];
  return (
    <div className="wethod-gantt-header__label wethod-gantt-header__label--week">
      {firstDay.format('D MMMM')} - {lastDay.format('D MMMM')}
    </div>
  );
};

GanttHeaderWeekLabel.defaultProps = {};

GanttHeaderWeekLabel.propTypes = {
  days: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
};

module.exports = GanttHeaderWeekLabel;
