const {
  makeAutoObservable,
  runInAction,
} = require('mobx');
const ProjectModel = require('@models/Project');

class ProjectStore {
  /**
   * @param {ProjectPlanRootStore} rootStore
   */
  constructor(rootStore) {
    this.rootStore = rootStore;
    /**
     * @type {?{id:number, name: string, project_plan_initialized:boolean,client: {id: number, corporate_name: string, project_type: {id:number, color:string, name: string}}}}
     */
    this.project = null;
    this.isLoading = false;
    this.initializingPlan = false;

    makeAutoObservable(this);
  }

  loadProject() {
    this.isLoading = true;
    ProjectModel.getInfo(this.rootStore.projectId).done((info) => {
      runInAction(() => {
        this.project = info;
        this.isLoading = false;
      });
    });
  }

  initializePlanFromScratch() {
    this.initializingPlan = true;
    ProjectModel.initProjectPlanFromScratch(this.project.id).done(() => {
      runInAction(() => {
        this.project.project_plan_initialized = true;
        this.initializingPlan = false;
      });
    });
  }
}

module.exports = ProjectStore;
