const React = require('react');
const PropTypes = require('prop-types');

const GanttActivitiesPanelActions = ({ children }) => (
  <div className="wethod-gantt-panel-activities__actions">{children}</div>
);

GanttActivitiesPanelActions.defaultProps = {};

GanttActivitiesPanelActions.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GanttActivitiesPanelActions;
