/**
 * @typedef {Object} ActivityData
 * @property {number} id
 * @property {string} type
 * @property {string} name
 * @property {string} from
 * @property {string} to
 * @property {number} sort
 */
class GanttActivity {
  /**
   * @typedef {Object} GanttActivity
   * @param {ActivityData} data
   * @param {GanttActivity[]} children
   * @param {boolean} expanded
   */
  constructor(data, children = [], expanded = false) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.from = data.from || null;
    this.to = data.to || null;
    this.expanded = expanded;
    this.children = children;
    this.sort = data.sort;
  }
}

module.exports = GanttActivity;
