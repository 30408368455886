const React = require('react');
const PropTypes = require('prop-types');
const GanttPanelAccordion = require('@common/Gantt/GanttPanelAccordion.react');
const GanttActivity = require('../models/GanttActivity');

/**
 * An activity representing a subtask in a Gantt panel.
 * A subtask is always contained tin a panel task.
 * @param {GanttActivity} subtask
 * @param showSubtaskDetail
 * @param deleteSubtask
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivitiesPanelSubtask = ({ subtask, showSubtaskDetail, deleteSubtask }) => {
  function showDetails(e) {
    e.stopPropagation();
    showSubtaskDetail(subtask.id);
  }

  function handleDeleteSubtask(e) {
    e.stopPropagation();
    deleteSubtask(subtask.id);
  }

  return (
    <GanttPanelAccordion label={subtask.name}
      deleteActivity={handleDeleteSubtask}
      showDetails={showDetails}
      hideExpandedIcon
      className="wethod-gantt-panel__accordion--subtask">
      <div className="wethod-gantt-panel__accordion--subtask-content" />
    </GanttPanelAccordion>
  );
};

GanttActivitiesPanelSubtask.propTypes = {
  subtask: PropTypes.instanceOf(GanttActivity).isRequired,
  showSubtaskDetail: PropTypes.func.isRequired,
  deleteSubtask: PropTypes.func.isRequired,
};

module.exports = GanttActivitiesPanelSubtask;
