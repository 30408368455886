const React = require('react');
const PropTypes = require('prop-types');
const GanttParentActivity = require('./GanttParentActivity.react');

const GanttAreaActivity = ({ name, width, left, onClick, id }) => {
  function onActivityClick() {
    onClick(id);
  }
  return (
    <GanttParentActivity className="wethod-gantt-activity--area" left={left} width={width} onClick={onActivityClick}>
      {name}
    </GanttParentActivity>
  );
};

GanttAreaActivity.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

module.exports = GanttAreaActivity;
