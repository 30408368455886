const React = require('react');
const PropTypes = require('prop-types');
const OpenSidebarButton = require('@common/OpenSidebarButton.react');
const DeleteButton = require('./DeleteButton.react');

/**
 * Actions available for activities in GanttPanel.
 * @param showDetails
 * @param deleteActivity
 * @return {JSX.Element}
 * @constructor
 */
const GanttPanelAccordionActions = ({ showDetails, deleteActivity }) => {
  const showDetailsAction = showDetails ? <OpenSidebarButton onClick={showDetails} /> : null;
  return (
    <div className="wethod-gantt-panel__accordion-actions">
      <DeleteButton onClick={deleteActivity} />
      {showDetailsAction}
    </div>
  );
};

GanttPanelAccordionActions.defaultProps = {
  showDetails: null,
};

GanttPanelAccordionActions.propTypes = {
  showDetails: PropTypes.func,
  deleteActivity: PropTypes.func.isRequired,
};

module.exports = GanttPanelAccordionActions;
