const React = require('react');
const ProjectInfoHeader = require('@common/InfoHeader/ProjectInfoHeader/ProjectInfoHeader.react');
const MasterLabel = require('./MasterLabel.react');

module.exports = class ProjectInfo extends React.Component {
  constructor(props) {
    super(props);

    this.handleDelegateClick = this.handleDelegateClick.bind(this);
  }

  handleDelegateClick() {
    if (!this.props.isLinked) {
      this.props.showDelegateModal(this.props);
    }
  }

  getDelegateButtonStyle() {
    let style = 'wethod-button invoice-plan__info-button';
    if (this.props.isLinked) {
      style += ' disabled';
    }
    return style;
  }

  getBillingGroupAction() {
    if (this.props.isMaster) {
      return (
        <button key="delegate"
          type="button"
          className="wethod-button invoice-plan__info-button"
          onClick={this.props.showLinkedProjectsModal}>
          Show linked projects
        </button>
      );
    }

    return (
      <button key="linked-projects"
        type="button"
        className={this.getDelegateButtonStyle()}
        onClick={this.handleDelegateClick}>
        Delegate invoice plan
      </button>
    );
  }

  render() {
    const masterLabel = this.props.isMaster ? <MasterLabel /> : null;
    return (
      <ProjectInfoHeader name={this.props.name}
        client={this.props.client.corporate_name}
        jocColor={this.props.project_type.color}
        id={this.props.id}
        descriptionSide={masterLabel}
        additionalActions={[this.getBillingGroupAction()]}
        clientName={this.props.client.corporate_name} />
    );
  }
};
