/* eslint-disable react/no-access-state-in-setstate,consistent-return,react/no-array-index-key,react/sort-comp,react/jsx-no-bind,no-shadow,no-plusplus,class-methods-use-this */
const React = require('react');
const _ = require('underscore');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const TextArea = require('@common/material-input/SidebarTextArea.react');
const ProjectSelect = require('../ProjectSelect.react');
const DestinationSelect = require('../../../containers/sidebar/FavoriteCitySelect');
const RequestEmployee = require('../RequestEmployee.react');
const CategoriesList = require('../TravelCategoriesList.react');
const format = require('../../../../../../../services/FormatService').formatDecimalNumber;

module.exports = class MainTravelEditableBody extends React.Component {
  getCategoriesAmount() {
    const amountList = {};
    if (this.props.travel && this.props.travel.travel_carriers) {
      this.props.travel.travel_carriers.forEach((item) => (amountList[item.type]
        ? amountList[item.type]++
        : amountList[item.type] = 1));
    }
    return amountList;
  }

  // Sum all values of the specific categories
  getTotalCosts() {
    if (this.props.travel && this.props.travel.travel_carriers) {
      const sum = this.props.travel.travel_carriers
        .reduce((sum, item) => (item.value ? sum + item.value : sum), 0);
      return format(sum, false, 1);
    }
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getJobOrder() {
    return this.props.travel.project
      ? this.getUnescapedValue(this.props.travel.project.job_order) : null;
  }

  render() {
    return (
      <div>
        <RequestEmployee {...this.props.travel} />
        <ProjectSelect project={this.props.travel.project}
          metadataService={this.props.metadataService}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate} />
        <ReadOnlyField placeholder="Job Order" value={this.getJobOrder()}>
          <span />
        </ReadOnlyField>
        <DestinationSelect name="destination"
          placeholder="Main destination"
          destination={this.props.travel.destination}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </DestinationSelect>
        <TextArea
          name="notes"
          value={this.props.travel.notes}
          placeholder="Purpose of the travel"
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </TextArea>
        <ReadOnlyField placeholder="Total travel costs" value={this.getTotalCosts()}>
          <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
        </ReadOnlyField>
        <CategoriesList selectCategory={this.props.changePage}
          totals={this.getCategoriesAmount()}
          errors={this.props.errors}
          missingValues={this.props.missingValues}
          editable />
      </div>
    );
  }
};
