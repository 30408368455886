const ProjectPlanActivity = require('./ProjectPlanActivity');

/**
 * @typedef {Object & ProjectPlanActivityData} ProjectPlanSubtaskData
 * @property {number} project_plan_task_id
 * @property {number} budget_task_days
 * @property {string} notes
 */
class ProjectPlanSubtask extends ProjectPlanActivity {
  /**
   * @param {ProjectPlanSubtaskData} data
   */
  constructor(data) {
    super(data);
    this.projecPlanTaskId = data.project_plan_task_id;
    this.notes = data.notes;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      project_plan_task_id: this.projecPlanTaskId,
      notes: this.notes,
    };
  }
}

module.exports = ProjectPlanSubtask;
