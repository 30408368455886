const request = require('./Request');

const Project = {
  getAll(options) {
    return Wethod.request('get:pipeline', options);
  },

  save(id, changes) {
    const model = new Wethod.PipelineApp.Pipeline.ProjectSafeModel({ id });

    return Wethod.request('save:pipeline:project', model, changes);
  },
  setReasonWhy(projectId, reasonWhyId) {
    return request({
      method: 'PUT',
      url: `/project/${projectId}/set-reason-why/${reasonWhyId}`,
    });
  },
  archive(id, archive) {
    const model = new Wethod.PipelineApp.Pipeline.ProjectSafeModel({
      id,
    });

    return Wethod.request('archive:pipeline:project', model, archive);
  },
  get(id) {
    return request({
      method: 'GET',
      url: `/project/${id}`,
    });
  },
  getInfo(id) {
    return request({
      method: 'GET',
      url: `/project/${id}/info`,
    });
  },
  initProjectPlanFromScratch(id) {
    return request({
      method: 'post',
      url: `/projects/${id}:init-project-plan-from-scratch`,
    });
  },
};

module.exports = Project;
