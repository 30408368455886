const ProjectPlanActivity = require('./ProjectPlanActivity');

/**
 * @typedef {Object & ProjectPlanActivityData} ProjectPlanTaskData
 * @property {number} project_plan_area_id
 * @property {number} budget_task_days
 * @property {string} notes
 */
class ProjectPlanTask extends ProjectPlanActivity {
  /**
   * @param {ProjectPlanTaskData} data
   * @param {ProjectPlanSubtask[]} children
   */
  constructor(data, children = []) {
    super(data, children);
    this.projecPlanAreaId = data.project_plan_area_id;
    this.budgetTaskDays = data.budget_task_days;
    this.notes = data.notes;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      project_plan_area_id: this.projecPlanAreaId,
      budget_task_days: this.budgetTaskDays,
      notes: this.notes,
    };
  }
}

module.exports = ProjectPlanTask;
