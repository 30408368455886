const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const IconButton = require('@common/Button/IconButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const FeatureService = require('@services/FeatureService');
const MoreOptionsMenuItem = require('../../../../budget/components/ProjectSidebar/MoreOptionMenuItem.react');

module.exports = class ProjectInfoActions extends React.Component {
  constructor(props) {
    super(props);

    this.optionsButton = null;

    this.setOptionButtonEl = this.setOptionButtonEl.bind(this);
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.props.isMenuOpen}
        onClose={this.props.closeMenu}
        centered
        anchorEl={this.optionsButton}>
        <MoreOptionsMenuItem href={`#pipeline/projects?id=${projectId}`}>
          Pipeline
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#pipeline/budget/${this.props.project.id}`}>
          Budget
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#planning/production-plan?project_id:${projectId}`}>
          Production plan
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${projectId}/report`}>
          Project report
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${this.props.project.id}/canvas`}>
          Canvas
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${projectId}/invoice-plan`}>
          Invoice plan
        </MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#timeline/${projectId}`}>
          Timeline
        </MoreOptionsMenuItem>
        <ShowIf condition={Wethod.featureService.isEnabled(FeatureService.PROJECT_PLAN)}>
          <MoreOptionsMenuItem href={`#project/${projectId}/project-plan`}>
            Project plan
          </MoreOptionsMenuItem>
        </ShowIf>
      </Menu>
    );
  }

  static getRequestAllocationButton() {
    return (
      <a className="wethod-button planning-people__project-info-allocation-request-button hidden-mobile"
        href="/#planning/allocation-requests"
        target="_blank">
        Request allocation
      </a>
    );
  }

  setOptionButtonEl(el) {
    this.optionsButton = el;
  }

  projectNeedsApproval() {
    return this.props.project.type.plan_upon_approval;
  }

  render() {
    return (
      <div className="planning-people__project-info-details--right">
        {this.projectNeedsApproval() ? ProjectInfoActions.getRequestAllocationButton()
          : null}
        <IconButton onClick={this.props.showMenu}
          inputRef={this.setOptionButtonEl}
          icon="more"
          border={false}
          size={IconButton.SIZE_MEDIUM} />
        {this.getOptionsMenu(this.props.project.id)}
        <IconButton onClick={this.props.closeInfo}
          icon="discard"
          border={false}
          size={IconButton.SIZE_MEDIUM} />
      </div>
    );
  }
};
