const React = require('react');
const PropTypes = require('prop-types');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const EnterIcon = require('@common/inputs/InlineCreator/EnterIcon.react');

require('./style.scss');

class InlineCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      focus: false,
      hover: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onKeyUp(e) {
    const isEnterKey = e.keyCode === 13;
    const { value } = e.target;
    if (isEnterKey && this.canBeSaved()) {
      this.setState({ value: '', focus: false, hover: false });
      this.props.save(value);
    }
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  onBlur() {
    if (!this.canBeSaved()) {
      this.setState({ focus: false });
    }
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  getFeedback() {
    if (this.props.isSaving) {
      return <span className="wethod-inline-creator__feedback">Saving...</span>;
    }
    if (this.canBeSaved()) {
      return (
        <div className="wethod-inline-creator__feedback wethod-inline-creator__feedback--savable">
          <EnterIcon />
          <span>Save</span>
        </div>
      );
    }
    return '';
  }

  isValueEmpty() {
    return this.state.value === '';
  }

  canBeSaved() {
    return !this.isValueEmpty();
  }

  render() {
    return (
      <div className="wethod-inline-creator__input"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <ShowIf condition={!this.props.isSaving}>
          <TextField onChange={this.onChange}
            readOnly={!this.state.focus && !this.state.hover}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            placeholder={this.props.placeholder}
            value={this.state.value}
            onKeyUp={this.onKeyUp} />
        </ShowIf>
        {this.getFeedback()}
      </div>
    );
  }
}

InlineCreator.defaultProps = {};

InlineCreator.propTypes = {
  placeholder: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
};

module.exports = InlineCreator;
