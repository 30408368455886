const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const GanttHeaderYearLabel = ({ days }) => (
  <div className="wethod-gantt-header__label wethod-gantt-header__label--year">
    {days[0].format('YYYY')}
  </div>
);

GanttHeaderYearLabel.defaultProps = {};

GanttHeaderYearLabel.propTypes = {
  days: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
};

module.exports = GanttHeaderYearLabel;
