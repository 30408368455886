const React = require('react');
const PropTypes = require('prop-types');

const GanttHeader = ({ children }) => (
  <div className="wethod-gantt-header">{children}</div>
);

GanttHeader.defaultProps = {};

GanttHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GanttHeader;
