const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');

const GanttHeaderDayLabel = ({ date }) => (
  <div className="wethod-gantt-header__label wethod-gantt-header__label--day">
    <span className="wethod-gantt-header__label--day-name">{date.format('dd')[0]}</span>
    <span>{date.format('D')} </span>
  </div>
);

GanttHeaderDayLabel.defaultProps = {};

GanttHeaderDayLabel.propTypes = {
  date: PropTypes.instanceOf(moment).isRequired,
};

module.exports = GanttHeaderDayLabel;
