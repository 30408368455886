const React = require('react');
const PropTypes = require('prop-types');
const GanttActivity = require('./GanttActivity.react');

const GanttSubtaskActivity = ({ name, width, left, id, onClick }) => {
  function onActivityClick() {
    onClick(id);
  }
  return (
    <GanttActivity className="wethod-gantt-activity--subtask" left={left} width={width} onClick={onActivityClick}>
      {name}
    </GanttActivity>
  );
};

GanttSubtaskActivity.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

module.exports = GanttSubtaskActivity;
