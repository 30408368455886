const {
  makeAutoObservable,
  runInAction,
  reaction,
} = require('mobx');
const ProjectPlanAreaModel = require('@models/ProjectPlanArea');
const ProjectPlanArea = require('../models/ProjectPlanArea');

class ProjectPlanAreaStore {
  /**
   * @param {ProjectPlanRootStore} rootStore
   * @property {ProjectPlanArea[]} areas
   * @property {boolean} isLoading
   * @property {boolean} isSaving
   * @property {boolean} isCreating
   * @property {number[]} deleting Ids of areas which delete is pending
   */
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.areas = [];
    this.isLoading = false;
    this.isSaving = false;
    this.isCreating = false;
    this.deleting = [];

    makeAutoObservable(this);

    /**
     * React to tasks change.
     */
    reaction(
      () => this.rootStore.tasksStore.tasks.map((task) => JSON.stringify(task)),
      () => this.updateChildren(),
    );
  }

  /**
   * Return sorted areas.
   * @return {ProjectPlanArea[]}
   */
  get sortedAreas() {
    return [...this.areas].sort((a, b) => a.sort - b.sort);
  }

  /**
   * @param {number} id
   * @return {ProjectPlanArea}
   */
  find(id) {
    return this.areas.find((area) => area.id === id);
  }

  /**
   * Update areas children.
   */
  updateChildren() {
    // eslint-disable-next-line no-param-reassign
    this.areas.forEach((area) => area.children = this.rootStore.tasksStore.findByArea(area.id));
  }

  loadAreas() {
    const { projectId } = this.rootStore;
    this.isLoading = true;
    ProjectPlanAreaModel.list(projectId).done((areas) => {
      runInAction(() => {
        this.areas = areas
          .map((area) => new ProjectPlanArea(area, this.rootStore.tasksStore.findByArea(area.id)));
        this.isLoading = false;
      });
    });
  }

  updateArea(id, data) {
    this.isSaving = true;
    return ProjectPlanAreaModel.update(id, data).done((newArea) => {
      runInAction(() => {
        this.areas = this.areas.map((area) => {
          if (area.id === id) {
            return new ProjectPlanArea(newArea, this.rootStore.tasksStore.findByArea(area.id));
          }
          return area;
        });

        this.isSaving = false;
      });
    });
  }

  createArea(data) {
    const { projectId } = this.rootStore;
    this.isCreating = true;
    return ProjectPlanAreaModel.create(projectId, data).done((newArea) => {
      runInAction(() => {
        this.areas = this.areas.concat(new ProjectPlanArea(newArea));
        this.isCreating = false;
      });
    });
  }

  deleteArea(id) {
    this.deleting.push(id);
    return ProjectPlanAreaModel.delete(id).done(() => {
      runInAction(() => {
        this.areas = this.areas.filter((area) => area.id !== id);
        this.deleting = this.deleting.filter((pendingId) => pendingId !== id);
      });
    });
  }

  isDeleting(id) {
    return this.deleting.includes(id);
  }
}

module.exports = ProjectPlanAreaStore;
