const {
  makeAutoObservable,
  runInAction,
} = require('mobx');
const AuthorizationModel = require('@models/Authorization');

class ProjectPlanAuthorizationStore {
  /**
   * @property {boolean} isLoading
   */
  constructor() {
    this.permissions = {};
    this.isLoading = false;

    makeAutoObservable(this);
  }

  /**
   * @param {string} appName
   */
  loadPermissions(appName) {
    this.isLoading = true;
    AuthorizationModel.getPermissions(appName).done((permissions) => {
      runInAction(() => {
        this.permissions[appName] = permissions;
        this.isLoading = false;
      });
    });
  }

  /**
   * Return true if user can do the given action related to the given appName.
   * @param {string} appName
   * @param {string} action
   * @return {boolean}
   */
  can(appName, action) {
    const appPermissions = this.permissions[appName];
    if (appPermissions) {
      return appPermissions[action] ? appPermissions[action] : false;
    }
    return false;
  }

  get canEdit() {
    return this.can('project_plan', 'edit');
  }

  get canView() {
    return this.can('project_plan', 'view');
  }
}

module.exports = ProjectPlanAuthorizationStore;
