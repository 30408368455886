const {
  makeAutoObservable,
} = require('mobx');
const GanttModel = require('@common/Gantt/models/Gantt');

class UiStore {
  /**
   * @property {string} ganttPrecision
   */
  constructor() {
    this.ganttPrecision = GanttModel.PRECISION_DAY;

    makeAutoObservable(this);
  }

  updateGanttPrecision(precision) {
    this.ganttPrecision = precision;
  }
}

module.exports = UiStore;
