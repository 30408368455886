const React = require('react');
const FormValidator = require('./FormValidator.react');

function withFormValidation(WrappedComponent) {
  return function WithFormValidation(props) {
    return (
      <FormValidator>
        <WrappedComponent {...props} />
      </FormValidator>
    );
  };
}

module.exports = withFormValidation;
