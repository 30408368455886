const { connect } = require('react-redux');
const Component = require('../../../components/modals/RepeatModal/RepeatModal.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  projectsInfo: state.planned_projects,
  zoom: state.zoom,
  international: state.international_calendar,
  isSaving: state.waiting_for.filter((request) => request === 'recurring-plan').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  recurringAdd: (employeeId, plannings) => dispatch(actions
    .recurringAdd(employeeId, plannings, false, false, false)),
  recurringDelete: (employeeId, plannings) => dispatch(actions
    .recurringDelete(employeeId, plannings)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
