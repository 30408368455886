const React = require('react');
const PropTypes = require('prop-types');

const GanttHeaderRow = ({ children }) => (
  <div className="wethod-gantt-header__row">{children}</div>
);

GanttHeaderRow.defaultProps = {};

GanttHeaderRow.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GanttHeaderRow;
