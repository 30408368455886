const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const DayPicker = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const FormSidebar = require('@common/sidebar/FormSidebar.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const ActivitySelect = require('../ProjectPlanActivitySelect.react');

/**
 *
 * @param {ProjectPlanArea[]} areas
 * @param {ProjectPlanTask[]} tasks
 * @param canEdit
 * @param save
 * @param isSaving
 * @return {JSX.Element}
 * @constructor
 */
const CreateSubtaskSidebar = ({ canEdit, save, isSaving, areas, findTasksByArea, subtasks }) => {
  function onSave(data) {
    const formattedData = {
      name: data.name,
      start_date: data.start_date,
      end_date: data.end_date,
      notes: data.notes,
      sort: data.sort,
    };

    return save(data.project_plan_task.id, formattedData);
  }

  const Body = ({
    updateErrors, data, errors, onTextFieldChange, onSelectChange, setData,
    onDateFieldChange,
  }) => {
    const getTasks = () => {
      if (!data.project_plan_area) {
        return [];
      }
      return findTasksByArea(data.project_plan_area.id);
    };

    function onAreaChange(e, area) {
      onSelectChange(e, area);
      // Reset task
      setData('project_plan_task', null);
    }

    return (
      <div>
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:200']}>
              <TextField
                id="name"
                name="name"
                label="Name"
                errorText={errors.name}
                onChange={onTextFieldChange}
                value={data.name} />
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={['required']}>
              <ActivitySelect id="area"
                availableItems={areas}
                name="project_plan_area"
                label="Area"
                onChange={onAreaChange}
                errorText={errors.project_plan_area}
                value={data.project_plan_area} />
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={['required']}>
              <ActivitySelect id="task"
                availableItems={getTasks()}
                name="project_plan_task"
                label="Task"
                onChange={onSelectChange}
                disabled={data.project_plan_area === null}
                errorText={errors.project_plan_task}
                value={data.project_plan_task} />
            </InputValidator>
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <DayPicker
              id="start"
              name="start_date"
              label="Starts"
              onChange={onDateFieldChange}
              value={data.start_date} />
          </InputWrapper>
          <InputWrapper>
            <DayPicker
              id="end"
              name="end_date"
              label="Ends"
              onChange={onDateFieldChange}
              value={data.end_date} />
          </InputWrapper>
        </Row>
        <Row>
          <InputWrapper>
            <TextArea
              id="notes"
              name="notes"
              label="Notes"
              onChange={onTextFieldChange}
              value={data.notes} />
          </InputWrapper>
        </Row>
      </div>
    );
  };

  const getSort = () => {
    const lastSubtask = subtasks[subtasks.length - 1];
    return lastSubtask ? lastSubtask.sort + 1 : 1;
  };

  return (
    <FormSidebar title="Create task"
      data={{
        name: '', start_date: null, end_date: null, notes: '', project_plan_area: null, project_plan_task: null, sort: getSort(),
      }}
      canEdit={canEdit}
      onSave={onSave}
      isSaving={isSaving}>
      <Body />
    </FormSidebar>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  isSaving: stores.store.subtasksStore.isSaving,
  save: (projectPlanTaskId, data) => stores.store.subtasksStore
    .createSubtask(projectPlanTaskId, data),
  areas: stores.store.areasStore.areas,
  tasks: stores.store.tasksStore.tasks,
  subtasks: stores.store.subtasksStore.sortedSubtasks,
  findTasksByArea: (areaId) => stores.store.tasksStore.findByArea(areaId),
  canEdit: stores.store.authorizationStore.canEdit,
});

module.exports = inject(storeMapper)(observer(CreateSubtaskSidebar));
