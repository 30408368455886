/* eslint-disable */
const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('@common/Icon/Icon.react');

const GanttNavigator = ({ onPastClick, onFutureClick, onTodayClick }) => {
  return (
    <div className="wethod-gantt-calendar-navigator">
      <div className="wethod-gantt-calendar-navigator__prev" onClick={onPastClick}>
        <Icon icon="back" color="black" />
      </div>
      <div className="wethod-gantt-calendar-navigator__today" onClick={onTodayClick}>Today</div>
      <div className="wethod-gantt-calendar-navigator__next" onClick={onFutureClick}>
        <Icon icon="forward" color="black" />
      </div>
    </div>
  );
};

GanttNavigator.defaultProps = {};

GanttNavigator.propTypes = {
  onPastClick: PropTypes.func.isRequired,
  onFutureClick: PropTypes.func.isRequired,
  onTodayClick: PropTypes.func.isRequired,
};

module.exports = GanttNavigator;
