const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment');
const GanttHeader = require('./GanttHeader.react');
const GanttBody = require('./GanttBody.react');
const GanttModel = require('./models/Gantt');
const GanttHeaderRow = require('./GanttHeaderRow.react');
const GanttHeaderCell = require('./GanttHeaderCell.react');
const GanttHeaderDayLabel = require('./GanttHeaderDayLabel.react');
const GanttHeaderMonthLabel = require('./GanttHeaderMonthLabel.react');
const GanttHeaderYearLabel = require('./GanttHeaderYearLabel.react');
const GanttHeaderWeekLabel = require('./GanttHeaderWeekLabel.react');
const GanttBodyColumn = require('./GanttBodyColumn.react');

class GanttCalendar extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  /**
   * Return days grouped by their week.
   * @return {*[]}
   */
  getGroupedDays() {
    const weeks = [];
    let currentWeek = [];

    this.props.days.forEach((day) => {
      if (currentWeek.length === 0 || day.isoWeek() === currentWeek[0].isoWeek()) {
        currentWeek.push(day);
      } else {
        weeks.push(currentWeek);
        currentWeek = [day];
      }
    });

    return weeks;
  }

  getWeeks() {
    return this.getGroupedDays();
  }

  getMonths() {
    const months = [];
    let currentMonth = [];

    this.props.days.forEach((day) => {
      if (currentMonth.length === 0 || day.month() === currentMonth[0].month()) {
        currentMonth.push(day);
      } else {
        months.push(currentMonth);
        currentMonth = [day];
      }
    });

    return months;
  }

  getYears() {
    const years = [];
    let currentYear = [];

    this.props.days.forEach((day) => {
      if (currentYear.length === 0 || day.year() === currentYear[0].year()) {
        currentYear.push(day);
      } else {
        years.push(currentYear);
        currentYear = [day];
      }
    });
    if (currentYear.length) {
      years.push(currentYear);
    }

    return years;
  }

  getSecondLevelHeader() {
    switch (this.props.precision) {
      case GanttModel.PRECISION_DAY:
        return this.getWeeks().map((days) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={days}>
            <GanttHeaderWeekLabel days={days} />
          </GanttHeaderCell>
        ));
      case GanttModel.PRECISION_WEEK:
        return this.getMonths().map((days) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={days}>
            <GanttHeaderMonthLabel days={days} />
          </GanttHeaderCell>
        ));
      case GanttModel.PRECISION_MONTH:
        return this.getYears().map((days) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={days}>
            <GanttHeaderYearLabel days={days} />
          </GanttHeaderCell>
        ));
      default:
        throw new Error(`Invalid Gantt precision '${this.props.precision}'`);
    }
  }

  getFirstLevelHeader() {
    switch (this.props.precision) {
      case GanttModel.PRECISION_DAY:
        return this.props.days.map((day) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={[day]} showCurrent>
            <GanttHeaderDayLabel date={day} />
          </GanttHeaderCell>
        ));
      case GanttModel.PRECISION_WEEK:
        return this.getWeeks().map((week) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={week} showCurrent>
            <GanttHeaderWeekLabel days={week} />
          </GanttHeaderCell>
        ));
      case GanttModel.PRECISION_MONTH:
        return this.getMonths().map((month) => (
          <GanttHeaderCell columnWidth={this.props.columnWidth} days={month} showCurrent>
            <GanttHeaderMonthLabel days={month} />
          </GanttHeaderCell>
        ));
      default:
        throw new Error(`Invalid Gantt precision '${this.props.precision}'`);
    }
  }

  getBody() {
    switch (this.props.precision) {
      case GanttModel.PRECISION_DAY:
        return this.props.days.map((day) => (
          <GanttBodyColumn days={[day]} dayWidth={this.props.columnWidth} />
        ));
      case GanttModel.PRECISION_WEEK:
        return this.getWeeks().map((weekDays) => (
          <GanttBodyColumn days={weekDays} dayWidth={this.props.columnWidth} />
        ));
      case GanttModel.PRECISION_MONTH:
        return this.getMonths().map((monthDays) => (
          <GanttBodyColumn days={monthDays} dayWidth={this.props.columnWidth} />
        ));
      default:
        throw new Error(`Invalid Gantt precision '${this.props.precision}'`);
    }
  }

  render() {
    return (
      <div className="wethod-gantt-calendar" ref={this.setRef} onScroll={this.onScroll}>
        <GanttHeader>
          <GanttHeaderRow>
            {this.getSecondLevelHeader()}
          </GanttHeaderRow>
          <GanttHeaderRow>
            {this.getFirstLevelHeader()}
          </GanttHeaderRow>
        </GanttHeader>
        <GanttBody>
          {
            this.getBody()
          }
          {this.props.children}
        </GanttBody>
      </div>
    );
  }
}

GanttCalendar.defaultProps = {};

GanttCalendar.propTypes = {
  precision: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(moment).isRequired,
  columnWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = GanttCalendar;
