const React = require('react');
const PropTypes = require('prop-types');

const GanttBody = ({ children }) => (
  <div className="wethod-gantt-body">{children}</div>
);

GanttBody.defaultProps = {};

GanttBody.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = GanttBody;
