'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var ProjectPlanComponent = require('../../apps/core/modules/project-plan');

Wethod.module('ProjectPlanApp', function (ProjectPlanApp) {
  ProjectPlanApp.Controller = {
    showProjectPlan: function (idProject) {
      var layout = new ProjectPlanApp.StructureLayoutView();
      layout.render();

      var element = React.createElement(ProjectPlanComponent, { projectId: idProject });
      var container = document.getElementById('root');
      if (container !== null) {
        ReactDOM.render(element, container);
      }
    },
  };
});
