const { connect } = require('react-redux');
const Component = require('../../../components/modals/RepeatErrorModal/RepeatErrorModal.react');
const actions = require('../../../actions');

const mapStateToProps = (state) => ({
  projectsInfo: state.planned_projects,
  isSaving: state.waiting_for.filter((request) => request === 'recurring-plan').length > 0,
  includeHolidays: state.planning_holiday_confirmed,
  includeWeekends: state.planning_weekend_confirmed,
  recurringModeTimestamp: state.recurring_mode_timestamp,
  modalType: state.modal_type,
});

const mapDispatchToProps = (dispatch) => ({
  confirmHolidayPlanning: () => dispatch(actions.confirmHolidayPlanning()),
  excludeHolidayPlanning: () => dispatch(actions.excludeHolidayPlanning()),
  confirmWeekendPlanning: () => dispatch(actions.confirmWeekendPlanning()),
  excludeWeekendPlanning: () => dispatch(actions.excludeWeekendPlanning()),
  setRecurringModeTimestamp: () => dispatch(actions.setRecurringModeTimestamp()),
  recurringAdd: (employeeId, plannings, includeHolidays, includeWeekends) => dispatch(actions
    .recurringAdd(employeeId, plannings, true, includeHolidays, includeWeekends)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
