const React = require('react');
const PropTypes = require('prop-types');
const Accordion = require('@common/Accordion/Accordion.react');
const GanttPanelAccordionActions = require('./GanttPanelAccordionActions.react');

const GanttPanelAccordion = ({
  children, label, className, expanded, onChange, showDetails,
  hideExpandedIcon, deleteActivity,
}) => (
  <Accordion className={`wethod-gantt-panel__accordion ${className}`}
    summary={(
      <GanttPanelAccordionActions
        showDetails={showDetails}
        deleteActivity={deleteActivity} />
)}
    expanded={expanded}
    onChange={onChange}
    label={label}
    showStatusIcon={!hideExpandedIcon}
    statusIconPosition={Accordion.STATUS_ICON_POSITION_START}>
    {children}
  </Accordion>
);

GanttPanelAccordion.defaultProps = {
  className: '',
  showDetails: null,
  expanded: false,
  onChange: null,
  hideExpandedIcon: false,
};

GanttPanelAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  showDetails: PropTypes.func,
  hideExpandedIcon: PropTypes.bool,
  deleteActivity: PropTypes.func.isRequired,
};

module.exports = GanttPanelAccordion;
