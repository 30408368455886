const React = require('react');
const PropTypes = require('prop-types');
const CollapseTransition = require('@common/Transition/CollapseTransition/CollapseTransition.react');

/**
 * Contains an activity and all its children.
 * An "actions" component is used to simulate space taken by GanttActivitiesPanelActions.
 * @param children
 * @param activity
 * @param expanded
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivityContainer = ({ children, activity, expanded }) => (
  <div className="wethod-gantt-activity-container">
    <div className="wethod-gantt-activity__row">
      {activity}
    </div>
    <CollapseTransition triggered={expanded}>
      <div className="wethod-gantt-activity__children">{children}</div>
      <div className="wethod-gantt-activity__row--actions" />
    </CollapseTransition>
  </div>
);

GanttActivityContainer.defaultProps = {
  expanded: false,
};

GanttActivityContainer.propTypes = {
  activity: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
};

module.exports = GanttActivityContainer;
