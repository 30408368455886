const React = require('react');
const PropTypes = require('prop-types');
const DateService = require('@services/DateService');
const moment = require('moment');

/**
 * Represent a column in the Gantt calendar body.
 * @param {moment[]} days
 * @param {number} dayWidth
 * @return {JSX.Element}
 */
const GanttBodyColumn = ({ days, dayWidth }) => {
  const width = days.length * dayWidth;

  const isSingleDay = days.length === 1;

  const getClassName = () => {
    let name = 'wethod-gantt-body__column';
    if (isSingleDay && DateService.isWeekend(days[0])) {
      name = `${name} ${name}--non-working`;
    }
    return name;
  };

  return (
    <div className={getClassName()} style={{ width }} />
  );
};

GanttBodyColumn.defaultProps = {};

GanttBodyColumn.propTypes = {
  dayWidth: PropTypes.number.isRequired,
  days: PropTypes.arrayOf(PropTypes.instanceOf(moment)).isRequired,
};

module.exports = GanttBodyColumn;
