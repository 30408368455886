const React = require('react');
const PropTypes = require('prop-types');
const Button = require('@common/Button/UnstyledButton.react');

/**
 * Basic structure for a calendar activity.
 * @param children
 * @param width
 * @param left
 * @param className
 * @param onClick
 * @return {JSX.Element}
 * @constructor
 */
const GanttActivity = ({ children, width, left, className, onClick }) => (
  <Button className={`wethod-gantt-activity ${className}`} style={{ width, left }} onClick={onClick}>
    {children}
  </Button>
);

GanttActivity.defaultProps = {
  width: 0,
  left: 0,
  className: '',
};

GanttActivity.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  left: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

module.exports = GanttActivity;
