const React = require('react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');

class ProjectPlanActivitySelect extends React.Component {
  static getInputValue(item) {
    return item.name;
  }

  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
    };

    this.onFilter = this.onFilter.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({ keyword });
  }

  onChange(e, option) {
    const item = this.props.availableItems.find((i) => i.id === option.value);
    this.props.onChange(e, item);
  }

  getFilteredItems() {
    return this.props.availableItems.filter((item) => item.name.includes(this.state.keyword));
  }

  getOptions() {
    return this.getFilteredItems().map((item) => (
      <SelectItem key={item.id}
        value={item.id}>
        {item.name}
      </SelectItem>
    ));
  }

  render() {
    return (
      <Autocomplete
        {...this.props}
        onChange={this.onChange}
        getInputValue={ProjectPlanActivitySelect.getInputValue}
        value={this.props.value}
        onFilter={this.onFilter}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
}

module.exports = ProjectPlanActivitySelect;
