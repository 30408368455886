const React = require('react');
const PropTypes = require('prop-types');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const SidebarManager = require('@common/sidebar/SidebarManager/SidebarManager.react');
const ModalManager = require('@common/Modal/ModalManager/ModalManager.react');
const ErrorBody = require('@common/SectionError/SectionError.react');
const Loader = require('../../../apps/core/modules/budget/components/SectionLoader.react');

const Section = ({ className, isLoading, title, helperUrl, children, authorized }) => {
  const getClassName = () => `wethod-section-body wethod-section-body--padded ${className}`;

  const getBody = () => {
    if (isLoading) {
      return <Loader />;
    }
    return children;
  };

  if (!isLoading && !authorized) {
    return <ErrorBody message="You are not allowed to be here!" />;
  }

  return (
    <div className={getClassName()}>
      <SectionHeader
        current_section={title}
        helper_url={helperUrl}
        big />
      <SidebarManager />
      <ModalManager />
      {getBody()}
    </div>
  );
};

Section.defaultProps = {
  className: '',
  isLoading: false,
  authorized: true,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  helperUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  authorized: PropTypes.bool,
};

module.exports = Section;
