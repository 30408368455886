const React = require('react');
const {
  observer,
  inject,
} = require('mobx-react');
const Section = require('@common/Section/Section.react');
const ProjectPlanBody = require('./ProjectPlanBody.react');

const ProjectPlan = ({ isLoading, authorized }) => (
  <Section title="Project Plan"
    authorized={authorized}
    helperUrl="project-plan/index"
    isLoading={isLoading}>
    <ProjectPlanBody />
  </Section>
);

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const mapper = (stores) => ({
  isLoading: stores.store.projectStore.isLoading || stores.store.authorizationStore.isLoading,
  authorized: stores.store.authorizationStore.canView,
});

module.exports = inject(mapper)(observer(ProjectPlan));
