const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const DayPicker = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const FormSidebar = require('@common/sidebar/FormSidebar.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
/**
 *
 * @param {ProjectPlanTask} task
 * @param canEdit
 * @param save
 * @param isSaving
 * @param findArea
 * @return {JSX.Element}
 * @constructor
 */
const TaskSidebar = ({ task, canEdit, save, isSaving, findArea }) => {
  function onSave(data) {
    const formattedData = {
      name: data.name,
      start_date: data.start_date,
      end_date: data.end_date,
      notes: data.notes,
    };

    return save(task.id, formattedData);
  }

  const hasChildren = task.children.length > 0;

  const getArea = () => findArea(task.projecPlanAreaId);

  const Body = ({ updateErrors, data, errors, editMode, onTextFieldChange, onDateFieldChange }) => (
    <div>
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:200']}>
            <TextField
              id="name"
              name="name"
              label="Name"
              errorText={errors.name}
              onChange={onTextFieldChange}
              readOnly={!editMode}
              value={data.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextField
            id="area"
            name="area"
            label="Area"
            readOnly
            value={getArea() ? getArea().name : ''} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <DayPicker
            id="start"
            name="start_date"
            label="Starts"
            onChange={onDateFieldChange}
            readOnly={!editMode || hasChildren}
            errorText={errors.start_date}
            value={data.start_date} />
        </InputWrapper>
        <InputWrapper>
          <DayPicker
            id="end"
            name="end_date"
            label="Ends"
            onChange={onDateFieldChange}
            readOnly={!editMode || hasChildren}
            errorText={errors.end_date}
            value={data.end_date} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextArea
            id="notes"
            name="notes"
            label="Notes"
            readOnly={!editMode}
            onChange={onTextFieldChange}
            value={data.notes} />
        </InputWrapper>
      </Row>
    </div>
  );

  return (
    <FormSidebar title="Task details"
      data={{
        name: task.name, start_date: task.start, end_date: task.end, notes: task.notes,
      }}
      canEdit={canEdit}
      onSave={onSave}
      isSaving={isSaving}>
      <Body />
    </FormSidebar>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  isSaving: stores.store.tasksStore.isSaving,
  save: (id, data) => stores.store.tasksStore.updateTask(id, data),
  findArea: (id) => stores.store.areasStore.find(id),
  canEdit: stores.store.authorizationStore.canEdit,
});

module.exports = inject(storeMapper)(observer(TaskSidebar));
