const React = require('react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const DropdownMenu = require('@common/Dropdown/DropdownMenu.react');
const sidebarService = require('@common/sidebar/SidebarManager/services/SidebarService');
const CreateAreaSidebar = require('./sidebars/CreateAreaSidebar.react');
const CreateTaskSidebar = require('./sidebars/CreateTaskSidebar.react');
const CreateSubtaskSidebar = require('./sidebars/CreateSubtaskSidebar.react');

const NewActivityButton = () => {
  function showCreateAreaForm() {
    sidebarService.openSidebar(<CreateAreaSidebar />);
  }

  function showCreateTaskForm() {
    sidebarService.openSidebar(<CreateTaskSidebar />);
  }

  function showCreateSubtaskForm() {
    sidebarService.openSidebar(<CreateSubtaskSidebar />);
  }
  return (
    <DropdownMenu label="New">
      <MenuItem onClick={showCreateAreaForm}>Area</MenuItem>
      <MenuItem onClick={showCreateTaskForm}>Task</MenuItem>
      <MenuItem onClick={showCreateSubtaskForm}>Subtask</MenuItem>
    </DropdownMenu>
  );
};

module.exports = NewActivityButton;
