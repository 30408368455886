const React = require('react');
const PropTypes = require('prop-types');
const Menu = require('@common/Menu/Menu.react');
const RoundedButton = require('@common/Button/RoundedButton.react');
const Icon = require('@common/Icon/Icon.react');

require('./style.scss');

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.getClassName = this.getClassName.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.uuid = window?.crypto?.randomUUID() ?? Math.random().toString(36);
  }

  getClassName() {
    return [
      'wethod-dropdown-menu',
      this.props.className,
    ].join(' ').trim();
  }

  getChildren() {
    return React.Children.map(this.props.children, (child) => React.cloneElement(child, {
      ...child.props,
      onClick: (...args) => {
        this.toggleMenu();
        if (child.props.onClick) {
          child.props.onClick(...args);
        }
      },
    }));
  }

  setMenuAnchorRef(ref) {
    this.button = ref;
  }

  toggleMenu() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <RoundedButton
          className="wethod-dropdown-menu__trigger"
          onClick={this.toggleMenu}
          aria-haspopup="true"
          aria-expanded={this.state.open.toString()}
          aria-controls={this.uuid}>
          {this.props.label}
          <Icon icon="chevron-down" size="1.25em" className="wethod-dropdown-menu__icon" />
        </RoundedButton>
        <div
          // TODO: this should be removed; Menu should anchor itself to its parent
          // by default (remove Portal). This should also improve keyboard
          // usability/accessibility since dom order is respected
          ref={this.setMenuAnchorRef} />
        <Menu
          id={this.uuid}
          open={this.state.open}
          onClose={this.toggleMenu}
          anchorEl={this.button}>
          {this.getChildren()}
        </Menu>
      </div>
    );
  }
}

DropdownMenu.defaultProps = {
  className: '',
};

DropdownMenu.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

module.exports = DropdownMenu;
