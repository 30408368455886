const React = require('react');
const PropTypes = require('prop-types');
const GanttActivityContainer = require('@common/Gantt/GanttActivities/GanttActivityContainer.react');
const GanttActivityArea = require('@common/Gantt/GanttActivities/GanttAreaActivity.react');
const GanttActivityTask = require('@common/Gantt/GanttActivities/GanttTaskActivity.react');
const GanttActivitySubtask = require('@common/Gantt/GanttActivities/GanttSubtaskActivity.react');
const GanttActivity = require('../models/GanttActivity');

/**
 * A tree of Gantt activities. This tree has 3 levels: areas, tasks and subtasks.
 */
class GanttActivities extends React.Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { option: null };
  }

  /**
   * @param {moment} startDate
   * @return {number}
   */
  getLeft(startDate) {
    const startPosition = startDate
      ? this.props.getDayPosition(moment(startDate)) : 0;

    return startPosition * this.props.columnWidth;
  }

  /**
   * @param {moment} startDate
   * @param {moment} endDate
   * @return {number}
   */
  getWidth(startDate, endDate) {
    const startPosition = startDate
      ? this.props.getDayPosition(moment(startDate)) : 0;
    const endPosition = endDate
      ? this.props.getDayPosition(moment(endDate)) : 0;

    return (endPosition - startPosition + 1) * this.props.columnWidth;
  }

  /**
   * @param {GanttSubtask[]} subtasks
   * @return {*}
   */
  getSubtasks(subtasks) {
    return subtasks
      .map((subtask) => {
        const left = this.getLeft(subtask.from);
        const width = this.getWidth(subtask.from, subtask.to);
        return (
          <GanttActivityContainer key={subtask.id}
            activity={(
              <GanttActivitySubtask name={subtask.name}
                left={left}
                width={width}
                id={subtask.id}
                onClick={this.props.showSubtaskDetail} />
)} />
        );
      });
  }

  /**
   * @param {GanttTask[]} tasks
   * @return {*}
   */
  getTasks(tasks) {
    return tasks
      .map((task) => {
        const left = this.getLeft(task.from);
        const width = this.getWidth(task.from, task.to);

        return (
          <GanttActivityContainer key={task.id}
            expanded={task.expanded}
            activity={(
              <GanttActivityTask id={task.id}
                name={task.name}
                left={left}
                width={width}
                subtasks={task.children}
                onClick={this.props.showTaskDetail} />
)}>
            {this.getSubtasks(task.children)}
          </GanttActivityContainer>
        );
      });
  }

  getAreas() {
    return this.props.activities.map((area) => {
      const left = this.getLeft(area.from);
      const width = this.getWidth(area.from, area.to);
      return (
        <GanttActivityContainer key={area.id}
          expanded={area.expanded}
          activity={(
            <GanttActivityArea name={area.name}
              left={left}
              width={width}
              id={area.id}
              onClick={this.props.showAreaDetail} />
)}>
          {this.getTasks(area.children)}
        </GanttActivityContainer>
      );
    });
  }

  render() {
    return (
      <div className="wethod-gantt-activities">
        {this.getAreas()}
      </div>
    );
  }
}

GanttActivities.defaultProps = {};

GanttActivities.propTypes = {
  getDayPosition: PropTypes.func.isRequired,
  columnWidth: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(GanttActivity).isRequired,
  showSubtaskDetail: PropTypes.func.isRequired,
  showTaskDetail: PropTypes.func.isRequired,
  showAreaDetail: PropTypes.func.isRequired,
};

module.exports = GanttActivities;
