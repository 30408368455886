const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('@common/Icon/Icon.react');
const Button = require('@common/TooltipFixed/IconButtonTooltip.react');

const DeleteButton = ({ onClick }) => (
  <Button label="Delete" onClick={onClick} className="wethod-icon-button--no-margin" borderless>
    <Icon icon="delete" />
  </Button>
);

DeleteButton.defaultProps = {};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

module.exports = DeleteButton;
