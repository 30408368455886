const React = require('react');
const PropTypes = require('prop-types');
const GanttNavigator = require('@common/Gantt/GanttNavigator.react');

const GanttPanel = ({ children, navigateToday, navigatePast, navigateFuture }) => (
  <div className="wethod-gantt-panel">
    <div className="wethod-gantt-panel__header">
      <GanttNavigator onPastClick={navigatePast}
        onTodayClick={navigateToday}
        onFutureClick={navigateFuture} />
    </div>
    <div className="wethod-gantt-panel__body">
      {children}
    </div>
  </div>
);

GanttPanel.defaultProps = {};

GanttPanel.propTypes = {
  children: PropTypes.node.isRequired,
  navigatePast: PropTypes.func.isRequired,
  navigateFuture: PropTypes.func.isRequired,
  navigateToday: PropTypes.func.isRequired,
};

module.exports = GanttPanel;
