const moment = require('moment');

/**
 * @typedef {Object} ProjectPlanActivityData
 * @property {number} id
 * @property {string} name
 * @property {number} sort
 * @property {string} start_date
 * @property {string} end_date
 */
class ProjectPlanActivity {
  /**
   * @abstract
   * @template {ProjectPlanActivityData} T
   * @param {T} data
   * @param {ProjectPlanActivity[]} children
   */
  constructor(data, children = []) {
    this.id = data.id;
    this.name = data.name;
    this.sort = data.sort;
    this.declaredStart = data.start_date || null;
    this.declaredEnd = data.end_date || null;
    this.children = children;
  }

  /**
   * Return actual start date.
   * @public
   * @return {string|null}
   */
  get start() {
    const ascendingFromChildren = [...this.children]
      .sort((a, b) => moment(a.start).valueOf()
        - moment(b.start).valueOf());

    if (ascendingFromChildren.length === 0) {
      return this.declaredStart;
    }

    return ascendingFromChildren[0].start;
  }

  /**
   * Return actual end date.
   * @public
   * @return {string|null}
   */
  get end() {
    const descendingToChildren = [...this.children]
      .sort((a, b) => moment(b.end).valueOf()
        - moment(a.end).valueOf());

    if (descendingToChildren.length === 0) {
      return this.declaredEnd;
    }

    return descendingToChildren[0].end;
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      sort: this.sort,
      start: this.start,
      end: this.end,
    };
  }
}

module.exports = ProjectPlanActivity;
