const React = require('react');
const PropTypes = require('prop-types');
const GanttActivity = require('./GanttActivity.react');
const GanttSubtask = require('../models/GanttSubtask');
const GanttParentActivity = require('./GanttParentActivity.react');

const GanttTaskActivity = ({ id, name, width, left, subtasks, onClick }) => {
  const hasChildren = subtasks.length > 0;
  function onActivityClick() {
    onClick(id);
  }

  if (hasChildren) {
    return (
      <GanttParentActivity className="wethod-gantt-activity--parent-task" left={left} width={width} onClick={onActivityClick}>
        {name}
      </GanttParentActivity>
    );
  }
  return (
    <GanttActivity className="wethod-gantt-activity--task" left={left} width={width} onClick={onActivityClick}>
      {name}
    </GanttActivity>
  );
};

GanttTaskActivity.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  subtasks: PropTypes.arrayOf(PropTypes.instanceOf(GanttSubtask)).isRequired,
  onClick: PropTypes.func.isRequired,
};

module.exports = GanttTaskActivity;
