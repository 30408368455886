const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const Gantt = require('@common/Gantt/Gantt.react');
const sidebarService = require('@common/sidebar/SidebarManager/services/SidebarService');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const GanttActivity = require('@common/Gantt/models/GanttActivity');
const AreaSidebar = require('./sidebars/AreaSidebar.react');
const TaskSidebar = require('./sidebars/TaskSidebar.react');
const SubtaskSidebar = require('./sidebars/SubtaskSidebar.react');
const DeleteActivityModal = require('./modals/DeleteActivityModal.react');

/**
 * @property {Object} props
 * @property {ProjectPlanArea[]} props.areas
 * @property {ProjectPlanTask[]} props.tasks
 */
class View extends React.Component {
  constructor(props) {
    super(props);

    this.showAreaDetail = this.showAreaDetail.bind(this);
    this.showTaskDetail = this.showTaskDetail.bind(this);
    this.showSubtaskDetail = this.showSubtaskDetail.bind(this);
    this.showDeleteAreaModal = this.showDeleteAreaModal.bind(this);
    this.showDeleteTaskModal = this.showDeleteTaskModal.bind(this);
    this.showDeleteSubtaskModal = this.showDeleteSubtaskModal.bind(this);
  }

  componentDidMount() {
    this.props.loadAreas();
    this.props.loadTasks();
    this.props.loadSubtasks();
  }

  getGanttActivities() {
    return this.props.areas.map((area) => {
      const tasks = area.children.map((task) => {
        const subtasks = task.children.map((subtask) => new GanttActivity({
          id: subtask.id,
          type: 'subtask',
          name: subtask.name,
          from: subtask.start,
          to: subtask.end,
          sort: subtask.sort,
        }));
        return new GanttActivity({
          id: task.id,
          name: task.name,
          type: 'task',
          from: task.start,
          to: task.end,
          sort: task.sort,
        }, subtasks);
      });
      return new GanttActivity({
        id: area.id,
        type: 'area',
        name: area.name,
        from: area.start,
        to: area.end,
        sort: area.sort,
      }, tasks);
    });
  }

  showAreaDetail(areaId) {
    const area = this.props.areas.find((a) => a.id === areaId);
    sidebarService.openSidebar(<AreaSidebar area={area} />);
  }

  showTaskDetail(taskId) {
    const task = this.props.tasks.find((t) => t.id === taskId);
    sidebarService.openSidebar(<TaskSidebar task={task} />);
  }

  showSubtaskDetail(subtaskId) {
    const subtask = this.props.subtasks.find((s) => s.id === subtaskId);
    sidebarService.openSidebar(<SubtaskSidebar subtask={subtask} />);
  }

  showDeleteAreaModal(areaId) {
    const area = this.props.areas.find((a) => a.id === areaId);
    modalService.openModal(<DeleteActivityModal activity={area} title="Delete area" />);
  }

  showDeleteTaskModal(taskId) {
    const task = this.props.tasks.find((t) => t.id === taskId);
    modalService.openModal(<DeleteActivityModal activity={task} title="Delete task" />);
  }

  showDeleteSubtaskModal(subtaskId) {
    const subtask = this.props.subtasks.find((s) => s.id === subtaskId);
    modalService.openModal(<DeleteActivityModal activity={subtask} title="Delete subtask" />);
  }

  render() {
    return (
      <div className="wethod-project-plan-view">
        <Gantt width={1400}
          precision={this.props.ganttPrecision}
          activities={this.getGanttActivities()}
          showAreaDetail={this.showAreaDetail}
          showTaskDetail={this.showTaskDetail}
          showSubtaskDetail={this.showSubtaskDetail}
          createArea={this.props.createArea}
          createTask={this.props.createTask}
          createSubtask={this.props.createSubtask}
          deleteArea={this.showDeleteAreaModal}
          deleteTask={this.showDeleteTaskModal}
          deleteSubtask={this.showDeleteSubtaskModal}
          isCreatingArea={this.props.isCreatingArea} />
      </div>
    );
  }
}

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  loadAreas: () => stores.store.areasStore.loadAreas(),
  loadTasks: () => stores.store.tasksStore.loadTasks(),
  loadSubtasks: () => stores.store.subtasksStore.loadSubtasks(),
  createArea: (area) => stores.store.areasStore.createArea(area),
  createTask: (projectPlanAreaId, task) => stores.store.tasksStore
    .createTask(projectPlanAreaId, task),
  createSubtask: (projectPlanTaskId, subtask) => stores.store.subtasksStore
    .createSubtask(projectPlanTaskId, subtask),
  areas: stores.store.areasStore.sortedAreas,
  tasks: stores.store.tasksStore.sortedTasks,
  subtasks: stores.store.subtasksStore.sortedSubtasks,
  ganttPrecision: stores.store.uiStore.ganttPrecision,
  isCreatingArea: stores.store.areasStore.isCreating,
});

module.exports = inject(storeMapper)(observer(View));
