const React = require('react');
const PropTypes = require('prop-types');

const SectionContent = ({ children }) => (
  <div className="wethod-section-body__content">{children}</div>
);

SectionContent.defaultProps = {};

SectionContent.propTypes = {
  children: PropTypes.node.isRequired,
};

module.exports = SectionContent;
