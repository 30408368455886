const request = require('./Request');

const ProjectPlanTask = {
  list(projectId) {
    return request({
      method: 'GET',
      url: `/projects/${projectId}/project-plan-tasks`,
    });
  },
  update(id, data) {
    return request({
      method: 'PATCH',
      url: `/project-plan-tasks/${id}`,
      data,
    });
  },
  create(projectPlanAreaId, data) {
    return request({
      method: 'POST',
      url: '/project-plan-tasks',
      data: {
        project_plan_area_id: projectPlanAreaId,
        ...data,
      },
    });
  },
  delete(id) {
    return request({
      method: 'DELETE',
      url: `/project-plan-tasks/${id}`,
    });
  },
};

module.exports = ProjectPlanTask;
