const React = require('react');
const {
  inject,
  observer,
} = require('mobx-react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const DayPicker = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const FormSidebar = require('@common/sidebar/FormSidebar.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');

/**
 *
 * @param {ProjectPlanSubtask} subtask
 * @param canEdit
 * @param save
 * @param isSaving
 * @param findArea
 * @param findTask
 * @return {JSX.Element}
 * @constructor
 */
const SubtaskSidebar = ({ subtask, canEdit, save, isSaving, findArea, findTask }) => {
  function onSave(data) {
    const formattedData = {
      name: data.name,
      start_date: data.start_date,
      end_date: data.end_date,
      notes: data.notes,
    };
    return save(subtask.id, formattedData);
  }
  /**
   * @return {ProjectPlanTask}
   */
  const getTask = () => findTask(subtask.projecPlanTaskId);

  const getArea = () => findArea(getTask().projecPlanAreaId);

  const Body = ({ updateErrors, data, errors, editMode, onTextFieldChange, onDateFieldChange }) => (
    <div>
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:200']}>
            <TextField
              id="name"
              name="name"
              label="Name"
              errorText={errors.name}
              onChange={onTextFieldChange}
              readOnly={!editMode}
              value={data.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextField
            id="task"
            name="task"
            label="Task"
            readOnly
            value={getTask() ? getTask().name : ''} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextField
            id="area"
            name="area"
            label="Area"
            readOnly
            value={getArea() ? getArea().name : ''} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <DayPicker
            id="start"
            name="start_date"
            label="Starts"
            onChange={onDateFieldChange}
            readOnly={!editMode}
            value={data.start_date} />
        </InputWrapper>
        <InputWrapper>
          <DayPicker
            id="end"
            name="end_date"
            label="Ends"
            onChange={onDateFieldChange}
            readOnly={!editMode}
            errorText={errors.end_date}
            value={data.end_date} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <TextArea
            id="notes"
            name="notes"
            label="Notes"
            readOnly={!editMode}
            onChange={onTextFieldChange}
            value={data.notes} />
        </InputWrapper>
      </Row>
    </div>
  );

  return (
    <FormSidebar title="Subtask details"
      data={{
        name: subtask.name, start_date: subtask.start, end_date: subtask.end, notes: subtask.notes,
      }}
      canEdit={canEdit}
      onSave={onSave}
      isSaving={isSaving}>
      <Body />
    </FormSidebar>
  );
};

/**
 * @param {{store: ProjectPlanRootStore}} stores
 */
const storeMapper = (stores) => ({
  isSaving: stores.store.subtasksStore.isSaving,
  save: (id, data) => stores.store.subtasksStore.updateSubtask(id, data),
  findArea: (id) => stores.store.areasStore.find(id),
  findTask: (id) => stores.store.tasksStore.find(id),
  canEdit: stores.store.authorizationStore.canEdit,
});

module.exports = inject(storeMapper)(observer(SubtaskSidebar));
