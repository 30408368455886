const React = require('react');
const PropTypes = require('prop-types');
const GanttActivity = require('./GanttActivity.react');

/**
 * Basic structure for a calendar activity with children.
 * @param children
 * @param width
 * @param left
 * @param className
 * @return {JSX.Element}
 * @constructor
 */
const GanttParentActivity = ({ children, width, left, className, onClick }) => (
  <GanttActivity className={`wethod-gantt-activity--parent ${className}`} left={left} width={width} onClick={onClick}>
    {children}
  </GanttActivity>
);

GanttParentActivity.defaultProps = {
  className: '',
};

GanttParentActivity.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

module.exports = GanttParentActivity;
